import React, { useEffect, useState } from "react";

import { Amenities, Properties, Cartland, Charles, Charles2, Christie, Corin, VideoHotspot } from "./content";

// import { Data } from '../assets/files/modalContents'

const Modal = (props) => {


    useEffect(() => {
    }, [])


    const Render = (title) => {
        switch (title) {
            case "Amenities":
                return <Amenities {...props} />
                break;
            case "Properties":
                return <Properties {...props} />
                break;
            case "Cartland":
                return <Cartland {...props} />
                break;
            case "Charles":
                return <Charles {...props} />
                break;
            case "Charles2":
                return <Charles2 {...props} />
                break;
            case "Christie":
                return <Christie {...props} />
                break;
            case "Corin":
                return <Corin {...props} />
                break;
            case "video-hotspot":
                return <VideoHotspot {...props} />
                break;
            // default:
            //     break;
        }
    }

    return (
        <>
            { Render(props.title) }
            <div className="dark-screen" onClick={ props.hideModal }></div>
        </>
    )
    
}


export default Modal
