import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

import './css/vicinity-map.css'
import './css/vicinity-map-v2.css'

import LoadScreen from "../Others/loadScreen";

// Default Component
const VicinityMap = () => {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <>
            <div className="content vicinity-map">
                <div className="back-cont">
                    <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
                </div>
                <LoadScreen isLoading={isLoading}/>
                <div className="text-section">
                    <div className="text-cont">
                        <h2>Vicinity Map</h2>
                        <h4 className="desc">Here at PHirst Editions, you’ll find yourself in a community maximized with opportunities and possibilities that will make your life truly living.</h4>

                        <div className="btn-cont">
                            <a href="#mapSectionV2">Check our vicinity map</a>
                            {/* <h6 onClick={ (e) => { } } id="viewGoogleMaps">Check us on Google Maps</h6> */}
                        </div>
                    </div>
                    <div className="contbg"></div>
                </div>
                {/* <Map /> */}
                <MapV2 />
                {/* <NearbyEstablishments/> */}
            </div>
        </>
    )
}

// Not used
const Map = () => {

    const [ isSchool, setIsSchool ] = useState(false)
    const [ isChurch, setIsChurch ] = useState(false)
    const [ isHospital, setIsHospital ] = useState(false)
    const [ isPark, setIsPark ] = useState(false)

    const desktopScrollAmount = 200
    const mobileScrollAmount = 120

    useEffect(() => {
        let mapContent = document.getElementById('mapContent')
        let mapImg = document.getElementById('mapImg')

        mapContent.scrollLeft = mapImg.clientWidth / 4

    }, [])

    const showSchool = () => {
        if (isSchool) {
            setIsSchool(prevState => !prevState)
        } else {
            setIsSchool(true)
            setIsChurch(false)
            setIsHospital(false)
            setIsPark(false)
        }
    }
    const showChurch = () => {
        if (isChurch) {
            setIsChurch(prevState => !prevState)
        } else {
            setIsSchool(false)
            setIsChurch(true)
            setIsHospital(false)
            setIsPark(false)
        }
    }
    const showHospital = () => {
        if (isHospital) {
            setIsHospital(prevState => !prevState)
        } else {
            setIsSchool(false)
            setIsChurch(false)
            setIsHospital(true)
            setIsPark(false)
        }
    }
    const showPark = () => {
        if (isPark) {
            setIsPark(prevState => !prevState)
        } else {
            setIsSchool(false)
            setIsChurch(false)
            setIsHospital(false)
            setIsPark(true)
        }
    }



    const left = () => {
        let scrollValue
        if(window.innerWidth <= 576 ) {
            scrollValue = mobileScrollAmount
        } else {
            scrollValue = desktopScrollAmount
        }
        let map = document.getElementById('mapContent')

        map.scrollLeft = map.scrollLeft - scrollValue
    }

    const right = () => {
        let scrollValue
        if(window.innerWidth <= 576 ) {
            scrollValue = mobileScrollAmount
        } else {
            scrollValue = desktopScrollAmount
        }
        let map = document.getElementById('mapContent')

        map.scrollLeft = map.scrollLeft + scrollValue
    }

    const topScroll = () => {
        let scrollValue
        if(window.innerWidth <= 576 ) {
            scrollValue = mobileScrollAmount
        } else {
            scrollValue = desktopScrollAmount
        }
        let map = document.getElementById('mapContent')

        map.scrollTop = map.scrollTop - scrollValue
    }

    const bottomScroll = () => {
        let scrollValue
        if(window.innerWidth <= 576 ) {
            scrollValue = mobileScrollAmount
        } else {
            scrollValue = desktopScrollAmount
        }
        let map = document.getElementById('mapContent')

        map.scrollTop = map.scrollTop + scrollValue
    }

    const instruction = () => {
        let mapInstruction = document.getElementById('mapInstructions')
        mapInstruction.classList.add('hide')
    }

    return (
        <div className="map-section" id="mapSection">
            <div className="map-instructions" id="mapInstructions" onClick={ instruction }>
                <div className="instructions">
                    <p>
                        Navigate throught the map using the controls.
                    </p>
                    <p>
                        Scroll down to see our list of establishments nearby. 
                    </p>
                    <small>Tap to close</small>
                </div>
            </div>
            <div className="map-location-control">
                <button className={ (isSchool ? "selected" : "") } onClick={ showSchool }>Schools</button>
                <button className={ (isChurch ? "selected" : "") } onClick={ showChurch }>Churches</button>
                <button className={ (isHospital ? "selected" : "") } onClick={ showHospital }>Hospitals</button>
                <button className={ (isPark ? "selected" : "") } onClick={ showPark }>Leisure Park</button>
            </div>
            <div className="map">
                <div className="map-content" id="mapContent">
                    <div className="img-cont">
                        <img className={ "schools-overlay img-overlay " + (isSchool ? "show" : "") }  src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/Schools-pinned-location.png" alt="" />
                        <img className={ "churches-overlay img-overlay " + (isChurch ? "show" : "") } src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/church-pinned-location.png" alt="" />
                        <img className={ "hospitals-overlay img-overlay " + (isHospital ? "show" : "") } src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/hospital-pinned-location.png" alt="" />
                        <img className={ "parks-overlay img-overlay " + (isPark ? "show" : "") } src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/leisure-parks-pinned-location.png" alt="" />

                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v1.jpg" id="mapImg" alt="" draggable="false" />
                    </div>
                </div>
                <div className="map-control">
                    <button className="left-control" onClick={ left }><i className="bi bi-chevron-left"></i></button>

                    <button className="right-control" onClick={ right }><i className="bi bi-chevron-right"></i></button>

                    <button className="top-control" onClick={ topScroll }><i className="bi bi-chevron-up"></i></button>

                    <button className="bottom-control" onClick={ bottomScroll }><i className="bi bi-chevron-down"></i></button>
                </div>

                <div className="map-location-control-mobile left">
                    <button onClick={ showSchool }>
                        <img className={ isSchool ? "" : "show"} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/School+unclicked.png" alt="" />
                        <img className={ isSchool ? "show" : ""} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/School+Clicked.png" alt="" />
                    </button>

                    <button onClick={ showChurch }>
                        <img className={ isChurch ? "" : "show"} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Church+unclicked.png" alt="" />
                        <img className={ isChurch ? "show" : ""} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Church+clicked.png" alt="" />
                    </button>
                </div>

                <div className="map-location-control-mobile right">
                    <button onClick={ showHospital }>
                        <img className={ isHospital ? "" : "show"} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Hospital+Unclicked.png" alt="" />
                        <img className={ isHospital ? "show" : ""} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Hospital+clicked.png" alt="" />
                    </button>

                    <button onClick={ showPark }>
                        <img className={ isPark ? "" : "show"} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Leisure+unclicked.png" alt="" />
                        <img className={ isPark ? "show" : ""} src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mobile-icon/Leisure+clicked.png" alt="" />
                    </button>
                </div>

            </div>
            <div className="nearby-redirect">
                <a href="#nearbyEstablishments">Check list of nearby establishments</a>
            </div>
        </div>
    )
}

// Map version currently in use
const MapV2 = () => {

    const [ isSchoolShown, setIsSchoolShown ] = useState(false)
    const [ isChurchShown, setIsChurchShown ] = useState(false)
    const [ isHospitalShown, setIsHospitalShown ] = useState(false)
    const [ isLeisureShown, setIsLeisureShown ] = useState(false)
    const [ isNearbyShown, setIsNearbyShown ] = useState(false)
    const [ hideMobileText, setHideMobileText] = useState(false)


    // const leftScroll = useRef(0)
    // const topScroll = useRef(0)
    // const xCoords = useRef(0)
    // const yCoords = useRef(0)

    useEffect(() => {
        let mapBody = document.getElementById("mapBody")
        let mapImg = document.getElementById('mapImgMain')
        if (window.innerWidth >= 575 && window.innerWidth <= 768) {
            mapBody.scrollLeft = mapImg.clientWidth / 4
        } else if (window.innerWidth >= 425 && window.innerWidth <= 576) {
            mapBody.scrollLeft = mapImg.clientWidth / 3.5
        } else if (window.innerWidth > 375.98 && window.innerWidth <= 425) {
            mapBody.scrollLeft = mapImg.clientWidth / 3
        } else if (window.innerWidth >= 320 && window.innerWidth < 375.98) {
            mapBody.scrollLeft = mapImg.clientWidth / 2.7
        }
        let mapObserver
        if (window.innerWidth > 768) {
            mapObserver = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    let mapGlow = document.getElementById("mapGlow")
                    mapGlow.style.animationPlayState = "running"
                    console.log("test")
                    mapObserver.unobserve(mapBody)
                }
            }, {
                threshold: .75
            })
    
            mapObserver.observe(mapBody)
        }

        return () => {
            mapObserver.disconnect()
        }
    }, [])

    useEffect(() => {
        toggleMapImgClass("schoolImg", isSchoolShown)
    }, [isSchoolShown])
    
    useEffect(() => {
        toggleMapImgClass("churchImg", isChurchShown)
    }, [isChurchShown])
    
    useEffect(() => {
        toggleMapImgClass("hospitalImg", isHospitalShown)
    }, [isHospitalShown])
    
    useEffect(() => {
        toggleMapImgClass("leisureImg", isLeisureShown)
    }, [isLeisureShown])

    useEffect(() => {
        var nearby = document.querySelectorAll("#mapNearby")
        var btnImg = document.querySelector(".map-img-btn.nearby")
        if (isNearbyShown) {
            nearby.forEach(item => {
                item.classList.add("show")  
            })
            btnImg.setAttribute('src', iconLinks.nearby[1])
            nearby.scrollTop = 0
        } else {
            nearby.forEach(item => {
                item.classList.remove("show")  
            })
            btnImg.setAttribute('src', iconLinks.nearby[0])
            
        }

    }, [isNearbyShown])

    useEffect(() => {
        var textOverlay = document.querySelector(".vicinity-map-v2 > #textOverlay")
        if (hideMobileText) {
            textOverlay.style.display = "none"
            let mapGlow = document.getElementById("mapGlow")
            mapGlow.style.animationPlayState = "running"
        }

    
    }, [hideMobileText])
    
    const toggleMapImgClass = (name, status) => {
        var element = document.getElementById(name)
        var btn = document.querySelector(".map-btn." + name)
        var btnImg = document.querySelector(".map-img-btn." + name)
        var link, linkAlt

        switch(name) {
            case "schoolImg":
                link = iconLinks.school[0]
                linkAlt = iconLinks.school[1]
            break
            case "churchImg":
                link = iconLinks.church[0]
                linkAlt = iconLinks.church[1]
            break
            case "hospitalImg":
                link = iconLinks.hospital[0]
                linkAlt = iconLinks.hospital[1]
            break
            case "leisureImg":
                link = iconLinks.leisure[0]
                linkAlt = iconLinks.leisure[1]
            break
        }

        if (status) {
            element.classList.add("show")
            btn.classList.add("active")
            btnImg.setAttribute('src', linkAlt)
        } else {
            element.classList.remove("show")
            btn.classList.remove("active")
            btnImg.setAttribute('src', link)
        }
    }
    

    const toggleBldgs = (bldg) => {
        switch(bldg) {
            case "school":
                if (isSchoolShown) {
                    setIsSchoolShown((prevState) => !prevState)
                } else {
                    setIsSchoolShown(true)
                    setIsChurchShown(false)
                    setIsHospitalShown(false)
                    setIsLeisureShown(false)
                }
            break
            case "church":
                if (isChurchShown) {
                    setIsChurchShown((prevState) => !prevState)
                } else {
                    setIsSchoolShown(false)
                    setIsChurchShown(true)
                    setIsHospitalShown(false)
                    setIsLeisureShown(false)
                }
            break
            case "hospital":
                if (isHospitalShown) {
                    setIsHospitalShown((prevState) => !prevState)
                } else {
                    setIsSchoolShown(false)
                    setIsChurchShown(false)
                    setIsHospitalShown(true)
                    setIsLeisureShown(false)
                }
            break
            case "leisure":
                if (isLeisureShown) {
                    setIsLeisureShown((prevState) => !prevState)
                } else {
                    setIsSchoolShown(false)
                    setIsChurchShown(false)
                    setIsHospitalShown(false)
                    setIsLeisureShown(true)
                }
            break
        }
    }


    let pos = { top: 0, left: 0, x: 0, y: 0}
    const touchAndDragMap = () => {
        if (window.innerWidth <= 768 && hideMobileText) {
            let mapBody = document.getElementById("mapBody")

            mapBody.addEventListener('touchstart', (e) => {
                            
                var touchLocation = e.targetTouches[0]

                // Map Body Current Scroll Values
                // leftScroll.current = mapBody.scrollLeft
                // topScroll.current = mapBody.scrollTop

                // Touch coords
                // xCoords.current = e.targetTouches[0].clientX
                // yCoords.current = e.targetTouches[0].clientY
                pos = {
                    // Current Scroll
                    left: mapBody.scrollLeft,
                    top: mapBody.scrollTop,
                    // Touch Position
                    x: e.targetTouches[0].clientX,
                    y: e.targetTouches[0].clientY
                }
                

                document.addEventListener('touchmove', touchMoveHandler)
                document.addEventListener('touchend', touchEndHandler)
            })
        }
    }

    const touchMoveHandler = (e) => {
        let mapBody = document.getElementById("mapBody")

        // The Distance touch has moved
        const dx = e.changedTouches[0].clientX - pos.x
        const dy = e.changedTouches[0].clientY - pos.y
        // Scroll the element
        mapBody.scrollLeft = pos.left - dx
        mapBody.scrollTop = pos.top - dy

    }

    const touchEndHandler = () => {
        document.removeEventListener('touchmove', touchMoveHandler)
        document.removeEventListener('touchend', touchEndHandler)
    }
    

    let iconLinks = {
        school: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/school.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/school-alt.png"
        ],
        church: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/church.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/church-alt.png"
        ],
        hospital: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/hospital.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/hospital-alt.png"
        ],
        leisure: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/leisure.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/leisure-alt.png"
        ],
        nearby: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/nearby.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/nearby-alt.png"
        ]
    } 

    return (
        <>
            <div className="vicinity-map-v2" id="mapSectionV2">    
                <div className="map-controls">
                    <h4>Vicinity Map</h4>
                    <div className="btn-cont desktop">
                        <button className="map-btn schoolImg" onClick={ () => toggleBldgs("school") }>School</button>
                        <button className="map-btn churchImg" onClick={ () => toggleBldgs("church") }>Churches</button>
                        <button className="map-btn hospitalImg" onClick={ () => toggleBldgs("hospital") }>Hospitals</button>
                        <button className="map-btn leisureImg" onClick={ () => toggleBldgs("leisure") }>Leisure Parks</button>
                        <hr />
                        <button className="map-btn nearby" onClick={ () => { setIsNearbyShown((prevState) => !prevState) }}>Nearby Establishments</button>
                    </div>
                    <div className="btn-cont tablet">
                        <img className="map-img-btn schoolImg" src={ iconLinks.school[0] } alt=""  onClick={ () => toggleBldgs("school") }/>
                        <img className="map-img-btn churchImg" src={ iconLinks.church[0]} alt=""  onClick={ () => toggleBldgs("church") }/>
                        <img className="map-img-btn hospitalImg" src={ iconLinks.hospital[0] } alt="" onClick={ () => toggleBldgs("hospital") } />
                        <img className="map-img-btn leisureImg" src={ iconLinks.leisure[0] } alt="" onClick={ () => toggleBldgs("leisure") } />
                        <img className="map-img-btn nearby" src={ iconLinks.nearby[0] } alt="" onClick={ () => { setIsNearbyShown((prevState) => !prevState) }}/>
                        {/* <button className="map-btn nearby" onClick={ () => { setIsNearbyShown((prevState) => !prevState) }}>Nearby Establishments</button> */}
                    </div>
                </div>
                <div className="text-overlay mobile" id="textOverlay" onClick={ () => {setHideMobileText(true)} }>
                    <div className="text-content">
                        <h2 className="mobile-header">Vicinity Map</h2>
                        <h4 className="mobile-subheader">Here at PHirst Editions, you’ll find yourself in a community maximized with opportunities and possibilities that will make your life truly living.</h4>
                        <div className="instructions">
                            <p>Touch and drag to navigate around the map.</p>
                            <p>Use the controls to toggle respective establishments</p>
                        </div>
                        <small>Tap to close</small>
                    </div>
                </div>
                <div className="map-body" id="mapBody" onTouchStart={touchAndDragMap}>

                    <img className="map-glow" id="mapGlow" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/vicinity-map-v2-glow.png" alt="" />
                    <img className="map-img-main" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/vicinity-map-v2.jpg" id="mapImgMain" alt="" />
                    <img className="map-img-school bldgs" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/schools-transparent-bg.png" alt="" id="schoolImg"/>
                    <img className="map-img-churches bldgs" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/churches-transparent-bg.png" alt="" id="churchImg"/>
                    <img className="map-img-hospitals bldgs" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/hospitals-transparent-bg.png" alt="" id="hospitalImg"/>
                    <img className="map-img-leisure bldgs" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/vicinity-map-v2/leisure-parks-transparent-bg.png" alt="" id="leisureImg"/>
                    
                    <div className="map-nearby desktop" id="mapNearby">
                        <img className="close-nearby" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/menu-close.png" alt="" onClick={ () => { setIsNearbyShown((prevState) => !prevState) }} />
                        <h4>Nearby Establishments</h4>

                        <div className="establishment tourist">
                            <h5>Tourist Attraction</h5>
                            <ul>
                                <li>Twin Lakes Tagaytay<br /><small>Nasugbu Hwy, Laurel, Batangas (4.6km, 6 mins)</small></li>
                                <li>Forest Crest Nature Hotel and Resort<br /><small>Km. 72 Batulao , Nasugbu, Batangas (1.4km, 1 min)</small></li>
                                <li>Chateau Royale<br /><small>Km. 72 Batulao , Nasugbu, Batangas (1.4km, 1 min)</small></li>
                                <li>Kawayan Cove <br /><small>Barangay Wawa, Nasugbu, Batangas (33.9km, 48 mins)</small></li>
                                <li>Calayo Beach Resort <br /> <small>Sitio Hulo, Calayo Rd, 4231 Batangas (40.7km, 58 mins)</small></li>
                                <li>Chateau Royal Hotel Resort Spa <br /> <small>Km. 72 Batulao, Nasugbu, 4231 Batangas (1.4km, 1 min)</small></li>
                                <li>Punta Fuego Country Club <br /> <small>Barangay Balaytigue, Peninsula Avenue, Nasugbu, Batangas (53km, 1hr 22 mins)</small></li>
                                <li>Hamilo Coast <br /> <small>Nasugbu, Batangas (49.7km, 1hr 11 mins)</small></li>
                                <li>Canyon Cove Hotel & Spa <br /> <small>3JRC+WQ6, Far East Road, Piloto Wawa, Nasugbu, 4231 Batangas (30.4km, 43 mins)</small></li>
                                <li>Tali Beach Cliff Jump Site <br /> <small>Balaytigue, Nasugbu, Batangas (39km, 58 mins)</small></li>
                            </ul>
                        </div>
                        <div className="establishment tourist">
                            <h5>Schools</h5>
                            <ul>
                                <li>Bliihan Elementary <br /> <small>Brgy Looc, Nasugbu, Batangas (41km, 1hr 2 mins)</small> </li>
                                <li>Malapad na Bato Elementary School <br /> <small>Malapad na Bato, Nasugbu, Batangas, Nasugbu (28Km, 39 mins)</small> </li>
                                <li>Looc National High School <br /> <small>Barangay Looc, Nasugbu 4213 Batangas (44.3km, 1hr 1 min)</small> </li>
                                <li>Christian College of Nasugbu <br /> <small>153 Apacible, Nasugbu, Batangas (27.8km, 35 mins)</small> </li>
                                <li>Batangas State University <br /> <small>Nasugbu Branch- 3J8G+MFC, Nasugbu, Batangas (27.6km, 34 mins)</small> </li>
                            </ul>
                        </div>
                        <div className="establishment tourist">
                            <h5>Transportation</h5>
                            <ul>
                                <li>Alps Bus station <br /><small>JP Laurel, Nasugbu, Batangas (27.6km, 34 mins)</small></li>
                            </ul>
                        </div>
                        <div className="establishment tourist">
                            <h5>Hospitals</h5>
                            <ul>
                                <li>Central Azucarera Don Pedro Hospital <br /><small>Lumbangan, Nasugbu, Batangas, Nasugbu (22.7km, 23 mins)</small></li>
                                <li>Nasugbu Rural Health Unit I <br /><small>National Highway, Nasugbu, 4231 Batangas (32.3km, 46 mins)</small></li>
                                <li>Apacible Memorial Hospital <br /><small>barangay lumbangan 4231 Nasugbu, Philippines (24.3km, 27 mins)</small></li>
                                <li>Jabez Medical Center <br /><small>JP Laurel St, Nasugbu, 4231 Batangas (26.6km, 30 mins)</small></li>
                                <li>Ospital ng Nasugbu <br /><small>P. Riñoza St. Brgy. 1, Nasugbu, Batangas (27.7km, 35 mins)</small></li>
                            </ul>
                        </div>
                        <div className="establishment tourist">
                            <h5>Malls</h5>
                            <ul>
                                <li>WalterMart Nasugbu <br /><small>J P Laurel St, Nasugbu, Batangas (25.4km, 27 mins)</small></li>
                                <li>Newstar Shopping Mart <br /><small>J P Laurel St, Nasugbu, Batangas (27.4km, 34 mins)</small></li>
                                <li>Twin Lakes <br /><small>Laurel, Batangas (4.6km, 6 mins)</small></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="map-nearby mobile" id="mapNearby">
                    <img className="close-nearby" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/menu-close.png" alt="" onClick={ () => { setIsNearbyShown((prevState) => !prevState) }} />
                    <h4>Nearby Establishments</h4>

                    <div className="establishment tourist">
                        <h5>Tourist Attraction</h5>
                        <ul>
                            <li>Twin Lakes Tagaytay</li>
                            <li>Forest Crest Nature Hotel and Resort</li>
                            <li>Chateau Royale</li>
                            <li>Kawayan Cove <br /><small>Barangay Wawa, Nasugbu, Batangas</small></li>
                            <li>Calayo Beach Resort <br /> <small>Sitio Hulo, Calayo Rd, 4231 Batangas</small></li>
                            <li>Chateau Royal Hotel Resort Spa <br /> <small>Km. 72 Batulao, Nasugbu, 4231 Batangas</small></li>
                            <li>Punta Fuego Country Club <br /> <small>Barangay Balaytigue, Peninsula Avenue, Nasugbu, Batangas</small></li>
                            <li>Hamilo Coast <br /> <small>Nasugbu, Batangas</small></li>
                            <li>Canyon Cove Hotel & Spa <br /> <small>3JRC+WQ6, Far East Road, Piloto Wawa, Nasugbu, 4231 Batangas</small></li>
                            <li>Tali Beach Cliff Jump Site <br /> <small>Balaytigue, Nasugbu, Batangas</small></li>
                        </ul>
                    </div>
                    <div className="establishment tourist">
                        <h5>Schools</h5>
                        <ul>
                            <li>Bliihan Elementary <br /> <small>Brgy Looc, Nasugbu, Batangas</small> </li>
                            <li>Malapad na Bato Elementary School <br /> <small>Malapad na Bato, Nasugbu, Batangas, Nasugbu</small> </li>
                            <li>Looc National High School <br /> <small>Barangay Looc, Nasugbu 4213 Batangas</small> </li>
                            <li>Christian College of Nasugbu <br /> <small>153 Apacible</small> </li>
                            <li>Batangas State University <br /> <small>Nasugbu Branch- 3J8G+MFC, Nasugbu, Batangas</small> </li>
                        </ul>
                    </div>
                    <div className="establishment tourist">
                        <h5>Transportation</h5>
                        <ul>
                            <li>Alps Bus station <br /><small>JP Laurel, Nasugbu, Batangas</small></li>
                        </ul>
                    </div>
                    <div className="establishment tourist">
                        <h5>Hospitals</h5>
                        <ul>
                            <li>Central Azucarera Don Pedro Hospital <br /><small>Lumbangan, Nasugbu, Batangas, Nasugbu</small></li>
                            <li>Nasugbu Rural Health Unit I <br /><small>National Highway, Nasugbu, 4231 Batangas</small></li>
                            <li>Apacible Memorial Hospital <br /><small>barangay lumbangan 4231 Nasugbu, Philippines</small></li>
                            <li>Jabez Medical Center <br /><small>JP Laurel St, Nasugbu, 4231 Batangas</small></li>
                            <li>Ospital ng Nasugbu <br /><small>P. Riñoza St. Brgy. 1, Nasugbu, Batangas</small></li>
                        </ul>
                    </div>
                    <div className="establishment tourist">
                        <h5>Malls</h5>
                        <ul>
                            <li>WalterMart Nasugbu <br /><small>J P Laurel St, Nasugbu, Batangas</small></li>
                            <li>Newstar Shopping Mart <br /><small>J P Laurel St, Nasugbu, Batangas</small></li>
                            <li>Twin Lakes <br /><small>Laurel, Batangas</small></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

// Not used
const NearbyEstablishments = () => {
    return (
        <>
            <div className="nearby-establishments" id="nearbyEstablishments">
                <div className="establishment-header">
                    <h3>List of Nearby Establishments</h3>
                </div>
                <div className="cont">
                    <div className="establishment tourist">
                        <h4>Tourist Attraction</h4>
                        <ul>
                            <li>Twin Lakes Tagaytay</li>
                            <li>Forest Crest Nature Hotel and Resort</li>
                            <li>Chateau Royale</li>
                            <li>Kawayan Cove <br /><small>Barangay Wawa, Nasugbu, Batangas</small></li>
                            <li>Calayo Beach Resort <br /> <small>Sitio Hulo, Calayo Rd, 4231 Batangas</small></li>
                            <li>Chateau Royal Hotel Resort Spa <br /> <small>Km. 72 Batulao, Nasugbu, 4231 Batangas</small></li>
                            <li>Punta Fuego Country Club <br /> <small>Barangay Balaytigue, Peninsula Avenue, Nasugbu, Batangas</small></li>
                            <li>Hamilo Coast <br /> <small>Nasugbu, Batangas</small></li>
                            <li>Canyon Cove Hotel & Spa <br /> <small>3JRC+WQ6, Far East Road, Piloto Wawa, Nasugbu, 4231 Batangas</small></li>
                            <li>Tali Beach Cliff Jump Site <br /> <small>Balaytigue, Nasugbu, Batangas</small></li>
                        </ul>

                    </div>
                    <div className="establishment schools">
                        <h4>Schools</h4>
                        <ul>
                            <li>Bliihan Elementary <br /> <small>Brgy Looc, Nasugbu, Batangas</small> </li>
                            <li>Malapad na Bato Elementary School <br /> <small>Malapad na Bato, Nasugbu, Batangas, Nasugbu</small> </li>
                            <li>Looc National High School <br /> <small>Barangay Looc, Nasugbu 4213 Batangas</small> </li>
                            <li>Christian College of Nasugbu <br /> <small>153 Apacible</small> </li>
                            <li>Batangas State University <br /> <small>Nasugbu Branch- 3J8G+MFC, Nasugbu, Batangas</small> </li>
                        </ul>
                    </div>
                    <div className="establishment transpo">
                        <h4>Transportation</h4>
                        <ul>
                            <li>Alps Bus station <br /><small>JP Laurel, Nasugbu, Batangas</small></li>
                        </ul>
                    </div>
                    <div className="establishment hospital">
                        <h4>Hospitals</h4>
                        <ul>
                            <li>Central Azucarera Don Pedro Hospital <br /><small>Lumbangan, Nasugbu, Batangas, Nasugbu</small></li>
                            <li>Nasugbu Rural Health Unit I <br /><small>National Highway, Nasugbu, 4231 Batangas</small></li>
                            <li>Apacible Memorial Hospital <br /><small>barangay lumbangan 4231 Nasugbu, Philippines</small></li>
                            <li>Jabez Medical Center <br /><small>JP Laurel St, Nasugbu, 4231 Batangas</small></li>
                            <li>Ospital ng Nasugbu <br /><small>P. Riñoza St. Brgy. 1, Nasugbu, Batangas</small></li>
                        </ul>
                    </div>
                    <div className="establishment mall">
                        <h4>Malls</h4>
                        <ul>
                            <li>WalterMart Nasugbu <br /><small>J P Laurel St, Nasugbu, Batangas</small></li>
                            <li>Newstar Shopping Mart <br /><small>J P Laurel St, Nasugbu, Batangas</small></li>
                            <li>Twin Lakes <br /><small>Laurel, Batangas</small></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VicinityMap