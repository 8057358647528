import React, { useEffect } from 'react'
// import brandLogo from '../assets/images/PHirstEditions.png'
import '../assets/css/navigation.css'

import { NavLink } from "react-router-dom";

const brandLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/brandlogo2.png'

const Navbar = (props) => {
    
    const toggleDropdown = () => {
        var mobile = document.getElementById('mobile')
        mobile.classList.toggle('show')
    }

    return (
        <React.Fragment>
            <nav id='navBar'>
                <NavLink to="/" >
                    <img className='logo' src={ brandLogo } alt="" draggable="false" onClick={ props.hideDropdown } />
                </NavLink>
                <div className='dropdown'>
                    <img className='navbar-toggler-desktop' src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/menu-icon.png" alt="" style={{ maxHeight: "32px" }} onClick={ toggleDropdown }/>
                    <ul className='desktop' draggable="false">
                        {/* <li>
                            <Link to="/home" >Home</Link>
                        </li> */}
                        <li>
                            <NavLink to="/about" className={ ({ isActive }) => isActive ? "active" : "" } >About Us</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/property-finder" className={ ({ isActive }) => isActive ? "active" : "" } >Property Finder</NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/explore" className={ ({ isActive }) => isActive ? "active" : "" } >Explore</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/news" className={ ({ isActive }) => isActive ? "active" : "" } >News</NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/contact-us" className={ ({ isActive }) => isActive ? "active" : "" } >Contact Us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/privacy-policy" className={ ({ isActive }) => isActive ? "active" : "" } >Privacy Policy</NavLink>
                        </li>
                    </ul>
                    <div className='mobile' id='mobile'>
                        <img className="navbar-toggler-mobile" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/menu-close-white.png" alt="" style={{ maxHeight: "32px" }} onClick={ toggleDropdown }/>
                        <ul>
                            <li>
                                <NavLink to="/" className={ ({ isActive }) => isActive ? "active" : "" } onClick={ toggleDropdown } >Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" className={ ({ isActive }) => isActive ? "active" : "" } onClick={ toggleDropdown }  >About Us</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/property-finder" className={ ({ isActive }) => isActive ? "active" : "" } >Property Finder</NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/explore" className={ ({ isActive }) => isActive ? "active" : "" } onClick={ toggleDropdown }  >Explore</NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/news" className={ ({ isActive }) => isActive ? "active" : "" } >News</NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/contact-us" className={ ({ isActive }) => isActive ? "active" : "" }  onClick={ toggleDropdown } >Contact Us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy" className={ ({ isActive }) => isActive ? "active" : "" }  onClick={ toggleDropdown } >Privacy Policy</NavLink>
                            </li>
                        </ul>
                        <div className='lattice'/>
                        <div className='color-overlay'></div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Navbar
