import React from "react";

const PageNotFound = () => {
    return (
        <div className="content page-error">
            <div className="text-cont">
            {/* <h1>404</h1> */}
            <img className="icon-404" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/404-icon.png" alt="" />
            <h1>Page Not Found</h1>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <a href="/">Go Home</a>
            </div>
        </div>
    )
}

export { PageNotFound }