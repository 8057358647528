import React from "react";
import { NavLink } from "react-router-dom";

const ImgButton = (props) => {
    return (
        <NavLink to={ props.link }>
            <div className="img-button">
                <div className="foreColor" style={{ backgroundColor: props.color }}></div>
                <h4>{props.title}</h4>
                <div className="img-holder"></div>
            </div>
        </NavLink>
    )
}

export default ImgButton