import React, { useEffect, useState } from "react"

import './assets/css/others.css'
import brandLogo from './assets/images/brandlogo2.png'

const LoadScreen = (props) => {

    useEffect(() => {
        var loader = document.getElementById('loader')

        if (!props.isLoading) {
            setTimeout(() => {
                loader.style.opacity = "0"
            }, 400)
            setTimeout(() => {
                loader.style.display = "none"
            }, 600)
        }
    }, [props.isLoading])

    return (
        <div className="content loading-screen" id="loader">
            <div>
                <img className="brand-logo" src={brandLogo} alt="" />
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default LoadScreen