export const coords = [
    {
        id: "amenities",
        title: "Amenities",
        shape: "poly",
        name: "amenities",
        coords: [436,527,452,534,458,554,571,602,605,578,636,591,636,611,642,629,725,671,759,673,869,599,859,550,887,558,916,575,940,595,954,604,979,612,1022,625,1066,638,1101,649,1124,656,1139,662,1152,671,1156,677,1156,689,1154,719,1140,717,1135,735,1132,752,1132,775,1136,795,1146,817,1164,837,1185,855,1217,873,1240,881,1266,887,1301,891,1337,888,1375,879,1403,867,1424,856,1410,841,1397,832,1371,813,1367,814,1361,797,1349,790,1348,790,1350,748,1254,705,1255,746,1245,741,1217,746,1217,740,1185,727,1189,674,1183,663,1168,650,1142,637,1119,631,1076,619,1075,614,1036,581,1017,577,995,569,969,553,960,545,931,528,914,437,910,428,901,422,887,419,770,434,732,404,666,432,664,473,614,487,580,486,502,517,462,513],
        // coords: [503,512,734,406,743,407,792,446,866,426,874,424,899,427,903,432,905,437,915,494,908,522,927,525,926,529,899,553,908,558,922,621,811,698,797,698,778,697,643,626,638,610,637,593,602,576,524,546,520,542,503,517],
        // coords: [176,633,304,712,311,710,437,623,421,605,450,589,336,534,320,545,316,543,306,548,297,535,251,558,259,567,186,604,179,596,168,591,165,595,192,623],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "properties",
        title: "Properties",
        shape: "poly",
        name: "properties",
        coords: [675,734,750,777,538,950,360,829,354,833,301,792,302,790,97,649,177,609,164,593,167,590,182,597,185,603,257,565,251,556,295,534,301,541,311,537,314,541,336,530,444,585,480,564,483,565,498,590,513,582,669,659,668,664,647,682,656,685,664,700,647,710,657,725],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "house1",
        title: "Cartland",
        shape: "poly",
        name: "house1",
        coords: [1198,196,1342,245,1447,187,1459,74,1454,73,1441,77,1358,58,1307,65,1307,69,1286,73,1286,78,1305,84,1304,139,1226,172,1197,165],
        // coords: [1172,199,1303,236,1308,235,1373,199,1381,202,1392,195,1393,161,1384,158,1386,114,1391,113,1391,96,1389,93,1380,95,1305,81,1234,99,1234,104,1248,109,1247,154,1195,179,1172,174],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "house2",
        title: "Charles",
        shape: "poly",
        name: "house2",
        coords: [1365,252,1500,308,1611,250,1627,157,1632,154,1637,132,1633,131,1618,136,1528,109,1435,128,1434,135,1449,140,1442,195,1367,225],
        // coords: [1383,261,1419,273,1459,288,1494,304,1582,256,1596,181,1601,179,1604,157,1589,163,1590,155,1499,112,1408,146,1406,165,1412,163,1410,189,1387,199],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "house3",
        title: "Charles2",
        shape: "poly",
        name: "house3",
        coords: [1554,332,1686,405,1821,340,1853,253,1860,249,1867,228,1864,227,1859,229,1730,154,1601,190,1596,214,1605,212,1598,245,1570,253],
        // coords: [1557,336,1618,365,1671,390,1725,418,1837,352,1843,333,1803,316,1820,271,1837,265,1840,259,1702,208,1619,227,1619,234,1633,240,1631,246,1569,270],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "house4",
        title: "Christie",
        shape: "poly",
        name: "house4",
        coords: [1693,410,1885,530,2044,460,2107,346,2045,328,2042,333,2029,337,1936,295,1827,315,1827,317,1845,327,1828,364,1734,396,1701,382],
        // coords: [1740,428,1815,478,1882,521,1932,551,2081,483,2144,378,2142,372,2088,356,2075,361,2063,364,1975,323,1875,342,1874,347,1891,356,1879,386,1778,420,1748,403],
        fillColor: "rgba(0,50,98,.65)",
    },
    {
        id: "house5",
        title: "Corin",
        shape: "poly",
        name: "house5",
        coords: [1926,552,2146,713,2323,645,2341,624,2286,589,2323,546,2356,536,2358,531,2166,424,2046,442,2045,448,2059,456,1965,481],
        // coords: [1957,540,1941,566,2128,707,2323,636,2331,615,2320,609,2378,546,2372,543,2358,546,2224,468,2155,480,2156,485,2164,490,2128,497,2133,503,2150,515,1990,563],
        fillColor: "rgba(0,50,98,.65)",
    },
    // {
    //     id: "video",
    //     title: "video-hotspot",
    //     shape: "poly",
    //     name: "video-hotspot",
    //     coords: [1137,711,1237,746,1242,740,1244,740,1253,744,1253,705,1353,746,1351,791,1365,797,1368,811,1435,854,1435,856,1421,866,1406,873,1390,879,1366,886,1341,891,1321,893,1297,893,1270,890,1243,884,1220,875,1198,864,1174,847,1156,829,1142,812,1135,795,1133,775,1132,750,1136,719],
    //     fillColor: "rgba(0,50,98,.65)",
    // },
]
