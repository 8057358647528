var APP_DATA = {
  "scenes": [
    {
      "id": "0-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.6327463864089893,
        "pitch": 0.14836025535204023,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.092688696269871,
          "pitch": 0.20205925039895334,
          "rotation": 6.283185307179586,
          "target": "1-kitchen-and-dining-area"
        },
        {
          "yaw": 2.1077691257372386,
          "pitch": 0.08589716662209845,
          "rotation": 7.853981633974483,
          "target": "6-hallway-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-kitchen-and-dining-area",
      "name": "Kitchen and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.1447025848328387,
        "pitch": 0.1561007904138858,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.433981067962355,
          "pitch": 0.7670516194135306,
          "rotation": 0.7853981633974483,
          "target": "0-living-room"
        },
        {
          "yaw": 0.38709068636588384,
          "pitch": 0.1164277664362725,
          "rotation": 7.0685834705770345,
          "target": "2-access-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-access-to-2-rooms",
      "name": "Access to 2 Rooms",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.304107684047409,
        "pitch": 0.0773468079834494,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.7870128962135352,
          "pitch": 0.033659907974222136,
          "rotation": 1.5707963267948966,
          "target": "3-storage-area"
        },
        {
          "yaw": 0.6630880623137916,
          "pitch": 0.09367775752869179,
          "rotation": 5.497787143782138,
          "target": "5-guest-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-storage-area",
      "name": "Storage Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.2548908349087995,
        "pitch": 0.11887993726856294,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.26450523829090145,
          "pitch": 0.2052576801081969,
          "rotation": 0,
          "target": "2-access-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-hallway-to-kitchen-and-dining--area",
      "name": "Hallway to Kitchen and Dining  Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.26467242295505855,
        "pitch": 0.02322160518553673,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.3736310790072128,
          "pitch": 0.2948313391859383,
          "rotation": 6.283185307179586,
          "target": "1-kitchen-and-dining-area"
        },
        {
          "yaw": 0.857988865863927,
          "pitch": 0.2557437814407457,
          "rotation": 0.7853981633974483,
          "target": "5-guest-bedroom"
        },
        {
          "yaw": -2.09586036590653,
          "pitch": 0.18626653990366826,
          "rotation": 0.7853981633974483,
          "target": "3-storage-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-guest-bedroom",
      "name": "Guest Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.5199801970238553,
        "pitch": 0.2568142492886736,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.14031327831013662,
          "pitch": 0.1123407303961983,
          "rotation": 0,
          "target": "4-hallway-to-kitchen-and-dining--area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-hallway-upstairs",
      "name": "Hallway Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.4275775071483423,
        "pitch": 0.15874429295423909,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.8366739507058156,
          "pitch": 0.3251641982580509,
          "rotation": 18.06415775814132,
          "target": "0-living-room"
        },
        {
          "yaw": -1.4033692413139889,
          "pitch": 0.14585925492587215,
          "rotation": 1.5707963267948966,
          "target": "1-kitchen-and-dining-area"
        },
        {
          "yaw": 2.8392942694884464,
          "pitch": 0.12201813250574212,
          "rotation": 12.566370614359176,
          "target": "8-upstairs"
        },
        {
          "yaw": -0.04918132173496481,
          "pitch": 0.14367948671464603,
          "rotation": 0,
          "target": "7-bathroom-1st-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.2300175141152447,
        "pitch": 0.09299405718362053,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.6984166521881363,
          "pitch": 0.027296437453662392,
          "rotation": 12.566370614359176,
          "target": "8-upstairs"
        },
        {
          "yaw": -1.4334776347014824,
          "pitch": 0.15387803506598807,
          "rotation": 1.5707963267948966,
          "target": "0-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.3449023068445545,
        "pitch": 0.12185040093428157,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.3464251669440355,
          "pitch": -0.20740007835486374,
          "rotation": 0.7853981633974483,
          "target": "9-hallway-2nd-floor"
        },
        {
          "yaw": -0.8326622309799987,
          "pitch": 0.5198484842302413,
          "rotation": 0,
          "target": "6-hallway-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.141411048241661,
        "pitch": 0.10871429031926638,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.272801727942575,
          "pitch": -0.012737187500395208,
          "rotation": 4.71238898038469,
          "target": "14-bedroom"
        },
        {
          "yaw": 1.5654149792066345,
          "pitch": 0.13128585716876806,
          "rotation": 0,
          "target": "10-hallway-extension-2nd-floor"
        },
        {
          "yaw": 1.8926499678560518,
          "pitch": 0.0031387000120854935,
          "rotation": 1.5707963267948966,
          "target": "13-office-area"
        },
        {
          "yaw": -2.851348607654989,
          "pitch": 0.042181880727568455,
          "rotation": 0,
          "target": "12-bathroom-2nd-floor"
        },
        {
          "yaw": 0.05761662044473148,
          "pitch": 0.9973257263983655,
          "rotation": 6.283185307179586,
          "target": "8-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-hallway-extension-2nd-floor",
      "name": "Hallway Extension 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
      },
      "linkHotspots": [
        {
          "yaw": -0.8781572980019057,
          "pitch": 0.03737761677606599,
          "rotation": 4.71238898038469,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        },
        {
          "yaw": -0.34054535369863537,
          "pitch": 0.060753266746683465,
          "rotation": 0,
          "target": "16-kids-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-hallway-extension-2nd-floor-2nd-view",
      "name": "Hallway Extension 2nd Floor 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.943284566115814,
        "pitch": 0.1600411128981314,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.5801489724944062,
          "pitch": 0.08324848773091809,
          "rotation": 0,
          "target": "12-bathroom-2nd-floor"
        },
        {
          "yaw": -1.2188427531416277,
          "pitch": 0.12746014570140396,
          "rotation": 6.283185307179586,
          "target": "16-kids-bedroom"
        },
        {
          "yaw": -2.80798633297492,
          "pitch": 0.15024891368073057,
          "rotation": 4.71238898038469,
          "target": "19-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "12-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.11330187507879685,
        "pitch": -0.04902338872502199,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.6195490335715013,
          "pitch": 0.0014357802842610567,
          "rotation": 0,
          "target": "9-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "13-office-area",
      "name": "Office Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.9680197376818818,
        "pitch": 0.09509837340498173,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.755742740395144,
          "pitch": 0.15374008684677065,
          "rotation": 0,
          "target": "9-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "14-bedroom",
      "name": "Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.057044435239184565,
        "pitch": 0.026348124178518617,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8008537033581504,
          "pitch": 0.03617460497747871,
          "rotation": 0,
          "target": "9-hallway-2nd-floor"
        },
        {
          "yaw": 0.0904088144906332,
          "pitch": 0.13170898285762433,
          "rotation": 0,
          "target": "15-bedroom-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "15-bedroom-2nd-view",
      "name": "Bedroom 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.358505544416216,
        "pitch": 0.11830879221296797,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.137933292852143,
          "pitch": 0.04017843140464805,
          "rotation": 4.71238898038469,
          "target": "9-hallway-2nd-floor"
        },
        {
          "yaw": -2.4237562355140305,
          "pitch": 0.16046097361304845,
          "rotation": 0,
          "target": "14-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "16-kids-bedroom",
      "name": "Kids' Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.5881353339150373,
        "pitch": 0.17495563118852608,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8577629897722243,
          "pitch": 0.13925225070000735,
          "rotation": 0,
          "target": "16-kids-bedroom"
        },
        {
          "yaw": 0.32162977002195703,
          "pitch": 0.2170619308493631,
          "rotation": 6.283185307179586,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "17-kids-bedroom-2nd-view",
      "name": "Kids' Bedroom 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.960825716402109,
        "pitch": 0.1749826984611893,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.8293338696659003,
          "pitch": 0.0862859791905457,
          "rotation": 1.5707963267948966,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "18-master-bathroom",
      "name": "Master Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.2749579537138498,
        "pitch": 0.09958979830387094,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.8871364057151974,
          "pitch": 0.06128513888014098,
          "rotation": 6.283185307179586,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        },
        {
          "yaw": 2.6498671820659503,
          "pitch": 0.41816568738174276,
          "rotation": 14.137166941154074,
          "target": "19-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "19-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.3169177572582687,
        "pitch": 0.11818334740256731,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8516657089047825,
          "pitch": 0.1407033624564793,
          "rotation": 0,
          "target": "21-balcony"
        },
        {
          "yaw": -1.2384207643283318,
          "pitch": 0.06667811617113273,
          "rotation": 4.71238898038469,
          "target": "18-master-bathroom"
        },
        {
          "yaw": -1.0943477545040192,
          "pitch": 0.061195619811165614,
          "rotation": 1.5707963267948966,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "20-master-bedroom-second-view",
      "name": "Master Bedroom Second View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.31225282705894664,
        "pitch": 0.06450445884871314,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.3626764893120846,
          "pitch": 0.058129248764961616,
          "rotation": 0,
          "target": "11-hallway-extension-2nd-floor-2nd-view"
        },
        {
          "yaw": -2.783040589618901,
          "pitch": -0.025114986466135747,
          "rotation": 0,
          "target": "21-balcony"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "21-balcony",
      "name": "Balcony",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.08868802080026583,
        "pitch": 0.33866824377082594,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.0833297725250226,
          "pitch": -0.04623837606844461,
          "rotation": 0,
          "target": "20-master-bedroom-second-view"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-charles-150",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA