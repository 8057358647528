var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-hallway",
      "name": "Entrance Hallway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.20155154707013,
        "pitch": 0.02986568748978158,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.5998909639653256,
          "pitch": 0.20229552089677405,
          "rotation": 0,
          "target": "2-living-room"
        },
        {
          "yaw": 1.5674106014039442,
          "pitch": 0.18118655408346918,
          "rotation": 0,
          "target": "1-dining-and-kitchen-area"
        },
        {
          "yaw": 2.126497342890355,
          "pitch": 0.03126956998931796,
          "rotation": 0,
          "target": "6-garden-sidewalk"
        },
        {
          "yaw": 2.121408995674809,
          "pitch": 0.12807719611733503,
          "rotation": 7.853981633974483,
          "target": "3-guest-bedroom"
        },
        {
          "yaw": -3.131371286917176,
          "pitch": 0.06355222386868498,
          "rotation": 0,
          "target": "5-bathroom-1st-floor"
        },
        {
          "yaw": 2.8988548361418873,
          "pitch": 0.22047320529930303,
          "rotation": 1.5707963267948966,
          "target": "4-hallway-to-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-dining-and-kitchen-area",
      "name": "Dining and Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.0952088048108273,
        "pitch": 0.3097698315533375,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.3930329234023642,
          "pitch": 0.043584224447069886,
          "rotation": 0,
          "target": "2-living-room"
        },
        {
          "yaw": -0.5072411001403072,
          "pitch": 0.03258203622569411,
          "rotation": 0,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -0.8212541113734968,
          "pitch": 0.10968425673462079,
          "rotation": 4.71238898038469,
          "target": "4-hallway-to-upstairs"
        },
        {
          "yaw": -2.37588682749392,
          "pitch": 0.10045088759522791,
          "rotation": 0.7853981633974483,
          "target": "3-guest-bedroom"
        },
        {
          "yaw": -2.9446429277652104,
          "pitch": 0.11125080618689154,
          "rotation": 5.497787143782138,
          "target": "6-garden-sidewalk"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.8301341764098069,
        "pitch": 0.29410786143087186,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.6441338073674832,
          "pitch": 0.06591759989771617,
          "rotation": 0,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -0.012955653195191985,
          "pitch": 0.08442683835230369,
          "rotation": 12.566370614359176,
          "target": "4-hallway-to-upstairs"
        },
        {
          "yaw": -0.642825709201091,
          "pitch": 0.027258967481223806,
          "rotation": 0.7853981633974483,
          "target": "3-guest-bedroom"
        },
        {
          "yaw": -0.641886058047433,
          "pitch": 0.15554549639366044,
          "rotation": 4.71238898038469,
          "target": "6-garden-sidewalk"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-guest-bedroom",
      "name": "Guest Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.3210026238215473,
        "pitch": 0.09611179676233306,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.261033303240982,
          "pitch": 0.06490476231723719,
          "rotation": 0,
          "target": "1-dining-and-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-hallway-to-upstairs",
      "name": "Hallway to Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.1601062248241085,
        "pitch": 0.06731264343973997,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.2777363262214543,
          "pitch": 0.11925635060211981,
          "rotation": 0,
          "target": "7-upstairs"
        },
        {
          "yaw": 1.9816862596282183,
          "pitch": -0.00013143483393918132,
          "rotation": 0,
          "target": "2-living-room"
        },
        {
          "yaw": 2.1761259314699544,
          "pitch": 0.11334280876296354,
          "rotation": 1.5707963267948966,
          "target": "1-dining-and-kitchen-area"
        },
        {
          "yaw": 1.7625547780400783,
          "pitch": 0.11116541151091397,
          "rotation": 4.71238898038469,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -2.5260279280219198,
          "pitch": 0.048783744299731424,
          "rotation": 0,
          "target": "5-bathroom-1st-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.004664186396844627,
        "pitch": -0.030959299778031024,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.6345384924733084,
          "pitch": 0.0674263466943188,
          "rotation": 0,
          "target": "4-hallway-to-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-garden-sidewalk",
      "name": "Service Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.8776986788205434,
        "pitch": 0.041431748057560114,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.30135532157306244,
          "pitch": -0.007317301754868666,
          "rotation": 0,
          "target": "1-dining-and-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.684593847371933,
        "pitch": 0.1077185446002833,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.5769917741402368,
          "pitch": -0.24855564073250314,
          "rotation": 6.283185307179586,
          "target": "8-hallway-2nd-floor"
        },
        {
          "yaw": 2.0554262600654347,
          "pitch": 0.5119415984351523,
          "rotation": 0,
          "target": "4-hallway-to-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.8498332523930721,
        "pitch": -0.009694021475745274,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.4847073577334484,
          "pitch": 0.030204884855091763,
          "rotation": 0,
          "target": "11-master-bedroom"
        },
        {
          "yaw": -1.7663382228327933,
          "pitch": -0.0039832156486721,
          "rotation": 4.71238898038469,
          "target": "10-bedroom"
        },
        {
          "yaw": 0.35380047063950215,
          "pitch": 0.07217812170201654,
          "rotation": 0,
          "target": "9-bathroom-2nd-floor"
        },
        {
          "yaw": -3.0479799707670985,
          "pitch": 0.913322073918744,
          "rotation": 6.283185307179586,
          "target": "7-upstairs"
        },
        {
          "yaw": -0.5,
          "pitch": -0.0009832156486721,
          "rotation": 6.283185307179586,
          "target": "17-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.26179954237359837,
        "pitch": 0.03225222942435657,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.383292484352264,
          "pitch": 0.26556824783646427,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-bedroom",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.029315998732045045,
        "pitch": -0.012889428758754207,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.10859632864591,
          "pitch": 0.11188420182647008,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.2495220965808258,
        "pitch": 0.08880314140671608,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.0566755988932144,
          "pitch": 0.02944583806675638,
          "rotation": 1.5707963267948966,
          "target": "15-balcony"
        },
        {
          "yaw": 0.1493437352536766,
          "pitch": 0.028155117890388937,
          "rotation": 7.853981633974483,
          "target": "14-master-bathroom"
        },
        {
          "yaw": 1.5022789424524454,
          "pitch": 0.28333261696993617,
          "rotation": 0,
          "target": "13-master-bedroom---closet-area"
        },
        {
          "yaw": 3.1244913794773073,
          "pitch": 0.13940111292784607,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "12-master-bedroom-2nd-view",
      "name": "Master Bedroom 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.1467868041997846,
        "pitch": 0.016308396628943456,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.2666220147063125,
          "pitch": 0.08157370451624857,
          "rotation": 0,
          "target": "14-master-bathroom"
        },
        {
          "yaw": 2.0759573566898544,
          "pitch": 0.08839193149291447,
          "rotation": 7.853981633974483,
          "target": "8-hallway-2nd-floor"
        },
        {
          "yaw": 1.570212161545351,
          "pitch": 0.12940251343301234,
          "rotation": 18.84955592153877,
          "target": "13-master-bedroom---closet-area"
        },
        {
          "yaw": -0.355433342481426,
          "pitch": 0.10842268017208312,
          "rotation": 0,
          "target": "15-balcony"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "13-master-bedroom---closet-area",
      "name": "Master Bedroom - Closet Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.05616583007056164,
        "pitch": 0.09804677745004398,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.11453158013227593,
          "pitch": 0.4920374129301237,
          "rotation": 0,
          "target": "11-master-bedroom"
        },
        {
          "yaw": 0.38538712056277014,
          "pitch": 0.09151142917514576,
          "rotation": 1.5707963267948966,
          "target": "15-balcony"
        },
        {
          "yaw": -0.22279968436508568,
          "pitch": 0.16521489606896367,
          "rotation": 4.71238898038469,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "14-master-bathroom",
      "name": "Master Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.012375971059594093,
        "pitch": 0.011610802592768366,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.6747171237006349,
          "pitch": 0.03267509424442672,
          "rotation": 0,
          "target": "11-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "15-balcony",
      "name": "Balcony",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.2807980315339123,
        "pitch": 0.10116078580825771,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.7503314948747501,
          "pitch": -0.008426002802735866,
          "rotation": 10.995574287564278,
          "target": "16-balcony-2nd-view"
        },
        {
          "yaw": 2.9177189867235898,
          "pitch": 0.06973177393017771,
          "rotation": 0,
          "target": "12-master-bedroom-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "16-balcony-2nd-view",
      "name": "Balcony 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.271624621742742,
        "pitch": 0.37192920786994677,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.089046665846581,
          "pitch": 0.1296614819082862,
          "rotation": 7.853981633974483,
          "target": "15-balcony"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "17-bedroom",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.309315998732045045,
        "pitch": 0.242889428758754207,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 4.14859632864591,
          "pitch": 0.11188420182647008,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
  ],
  "name": "360-corin-120",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA