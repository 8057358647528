import React from "react";

import './assets/css/houseunit.css'

const GreenBar = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/rectangle-green.png'
const Card1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/cartland-card.png'
const UnitDetails1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/unit-details.png'


const HouseUnit = () => {


    const leftScroll = () => {
        const cardCont = document.getElementById("cardContainer")

        let currentScroll = cardCont.scrollLeft
        if (currentScroll >= 200) {
            cardCont.scrollLeft = currentScroll - 340
        } else {
            cardCont.scrollLeft = 0
        }
        
    }

    const rightScroll = () => {
        const cardCont = document.getElementById("cardContainer")

        let currentScroll = cardCont.scrollLeft
        
        cardCont.scrollLeft = currentScroll + 340
    }


    return (
        <div className="content house-unit">
            <div className="header">
                <div>
                    <h2>House Units</h2>
                    <img className="bar" src={ GreenBar } alt="" />
                </div>
                <p>	A home expresses the personality of the people who dwell in them. Choose from these wonderful shelters inspired by literary geniuses with different personalities.</p>
            </div>
            <div className="units">
                <div className="button-control">
                    <button onClick={ leftScroll }>&lt;</button>
                    <button onClick={ rightScroll} >&gt;</button>
                </div>
                <div className="card-container" id="cardContainer">
                    <Card 
                        title="Cartland 54" 
                        desc="Start with the charms of a simply elegant home like the Cartland 54. This unit provides enough space for your family to venture into new adventures!" 
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-cartland.jpg"
                        url="/cartland"
                    />

                    <Card 
                        title="Christie 90" 
                        desc="Let your mind wander and wonder and explore the possibilities you can do with Christie 90. This unit maximizes your ability to make things happen in your home." 
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-christie.jpg"
                        url="/christie"
                    />

                    <Card 
                        title="Corin 105" 
                        desc="Make it exceptional, make it grand! The Corin 105 unit lets you live a life that aims to make significant leaps in dreams." 
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-corin.jpg"
                        url="/corin"
                    />

                    <Card 
                        title="Charles 70" 
                        desc="Your home is a home that takes you on a journey to contemporary bliss. The Charles 70 unit brings you closer to a life well lived." 
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-charles.jpg"
                        url="/charles"
                    />

                    <Card 
                        title="Charles 70 (Remastered)" 
                        desc="What makes life interesting is that you can expand everything to fit your dreams. The Charles unit can be expanded to let your visions take hold and start manifesting." 
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-charles.jpg"
                        url="/charles-remastered"
                    />
                </div>
            </div>
        </div>
    )
}

const Card = (props) => {
    return(
        <div className="card">
            <div className="card-img" style={{ backgroundImage: "url(" + props.src + ")"}}>
                <h6>{props.title}</h6>
            </div>
            <p>{props.desc}</p>
            <img className="details" src={ UnitDetails1 } alt="" />
            <a href={ props.url }>
                <button className="check-btn">Check Unit</button>
            </a>
        </div>
    )
}

export default HouseUnit