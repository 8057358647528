import React, { useState, useEffect } from "react"
import '../assets/css/finder.css'

import ImgButton from "./imgButton"
import TopControlButton from "./topControl"
import SideControlButton from "./sideControl"
import { Outlet } from "react-router-dom"

import LoadScreen from "../../Others/loadScreen"

const PropertyFinder = () => {
    return (
        <div className="content finder">
            <div className="wrapper">
                <h1>Choose Your <i>PHirst</i> Property</h1>
            </div>
            <div className="wrapper-buttons">
                <ImgButton link="/properties/tanza" title="Tanza" color="red" />
                <ImgButton link="/properties/lipa" title="Lipa" color="yellow" />
                <ImgButton link="/properties/calamba" title="Calamba" color="blue" />
                <ImgButton link="/properties/sanpablo" title="San Pablo" color="grey" />
                <ImgButton link="/properties/baliwag" title="Baliwag" color="red" />
                <ImgButton link="/properties/tayabas" title="Tayabas" color="yellow" />
                <ImgButton link="/properties/naic" title="Naic" color="blue" />
                <ImgButton link="/properties/magalang" title="Magalang" color="grey" />
                <ImgButton link="/properties/gentri" title="Gen Tri" color="red" />
                <ImgButton link="/properties/pandi" title="Pandi" color="yellow" />
            </div>
        </div>
    )
}

const VicinityMapAlt = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [ isContentShown, setContentShown ] = useState(false)
    const [ btnDesc, setBtnDesc] = useState("")


    let objDesc = {
        description: [ "View nearby establishments", "Hide nearby establishments" ]
    }

    useEffect(() => {
        var additionalContent = document.getElementById('additionalContent')
        let contWidth = additionalContent.offsetWidth

        additionalContent.style.right = "-"+(contWidth)+"px"

        setIsLoading(false) 
        
        setBtnDesc(objDesc.description[0])

    }, [])

    useEffect(() => {

        var additionalContent = document.getElementById('additionalContent')
        let contWidth = additionalContent.offsetWidth

        if (isContentShown) {
            additionalContent.style.right = "0px"
            additionalContent.classList.remove("hide-content")
            setBtnDesc(objDesc.description[1])
        } else {
            additionalContent.style.right = "-"+(contWidth)+"px"
            additionalContent.classList.add("hide-content")
            setBtnDesc(objDesc.description[0])
        }

    }, [isContentShown])

    const scrollLeft = () => {
        disabledButton()

        var cont = document.getElementById('establishmentsCont')
        var item = document.querySelector('div.establishment')

        cont.scrollLeft = (cont.scrollLeft - item.offsetWidth)


    }

    const scrollRight = () => {
        disabledButton()

        var cont = document.getElementById('establishmentsCont')
        var item = document.querySelector('div.establishment')


        cont.scrollLeft = (cont.scrollLeft + item.offsetWidth)
    }

    const disabledButton = () => {

        var controlLeft = document.getElementById('controlLeft')
        var controlRight = document.getElementById('controlRight')

        controlLeft.classList.add("disabled")
        controlRight.classList.add("disabled")
        setTimeout(()=> {
            controlLeft.classList.remove("disabled")
            controlRight.classList.remove("disabled")
        }, 800)
        
    }

    const switchToEstablishments = (e) => {
        console.log(e)
        var finderCont = document.getElementById('finderContent')
        var vicinityMap = document.getElementById('vicinityMap')

        finderCont.scrollLeft =+ vicinityMap.offsetWidth
    }

    const switchToMap = (e) => {
        var finderCont = document.getElementById('finderContent')
        var vicinityMap = document.getElementById('vicinityMap')

        finderCont.scrollLeft =- vicinityMap.offsetWidth
    }

    const toggleContent = () => {
        setContentShown((prevState) => !prevState)
        let nearby = document.getElementById('nearby')
        let nearbyAlt = document.getElementById('nearbyAlt')

        nearby.classList.toggle('show')
        nearbyAlt.classList.toggle('show')
    }


    const touristAttraction = { names: [ 
        "Twin Lakes",
        "Forest Crest Nature Hotel and Resort",
        "Chateau Royale",
        "Kawayan Cove|Barangay Wawa, Nasugbu, Batangas",
        "Calayo Beach Resort|Sitio Hulo, Calayo Rd, 4231 Batangas", 
        "Chateau Royal Hotel Resort Spa|Km. 72 Batulao, Nasugbu, 4231 Batangas",
        "Punta Fuego Country Club|Barangay Balaytigue, Peninsula Avenue, Nasugbu, Batangas",
        "Hamilo Coast|Nasugbu, Batangas",
        "Canyon Cove Hotel & Spa|3JRC+WQ6, Far East Road, Piloto Wawa, Nasugbu, 4231 Batangas",
        "Tali Beach Cliff Jump Site|Balaytigue, Nasugbu, Batangas"
    ]}

    const schools = { names: [ 
        "Bulihan Elementary|Brgy Looc, Nasugbu, Batangas",
        "Malapad na Bato Elementary School|Malapad na Bato, Nasugbu, Batangas, Nasugbu",
        "Looc National High School|Barangay Looc, Nasugbu 4213 Batangas",
        "Christian College of Nasugbu|153 Apacible",
        "Batangas State University|Nasugbu Branch - 3J8G+MFC, Nasugbu, Batangas"
    ]}

    const transpo = { names: [
        "Alps Bus station|JP Laurel, Nasugbu, Batangas"
    ]}

    const hospitals = { names: [
        "Central Azucarera Don Pedro Hospital|Lumbangan, Nasugbu, Batangas, Nasugbu",
        "Nasugbu Rural Health Unit I|National Highway, Nasugbu, 4231 Batangas",
        "Apacible Memorial Hospital|Barangay lumbangan 4231 Nasugbu, Philippines",
        "Jabez Medical Center|JP Laurel St, Nasugbu, 4231 Batangas",
        "Ospital ng Nasugbu|P. Riñoza St. Brgy. 1, Nasugbu, Batangas"
    ]}

    const malls = { names: [
        "WalterMart Nasugbu|JP Laurel St, Nasugbu, Batangas",
        "Newstar Shopping Mart|JP Laurel St, Nasugbu, Batangas",
        "Twin Lakes|Laurel, Batangas"
    ]}

    return (
        <div className="content finder-main">
            <div className="back-cont">
                <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
            </div>
            <LoadScreen isLoading={isLoading}/>
                <div className="text-content">
                    <h2>Vicinity Map</h2>
                    <h4 className="desc">Here at PHirst Editions, you’ll find yourself in a community maximized with opportunities and possibilities that will make your life truly living.</h4>

                    <div className="btn-cont">
                        <a href="#finderContent"><h6>Check our vicinity map</h6></a>
                        {/* <h6 onClick={ (e) => { } } id="viewGoogleMaps">Check us on Google Maps</h6> */}
                    </div>
                    <div className="contbg"></div>
                </div>
            <div className="finder-wrapper">
                <div className="side-controls">
                    <SideControlButton link="/properties/tanza" text="Tanza" />
                    <SideControlButton link="/properties/lipa" text="Lipa" />
                    
                    <SideControlButton link="/properties/calamba" text="Calamba" />
                    <SideControlButton link="/properties/sanpablo" text="San Pablo" />

                    <SideControlButton link="/properties/baliwag" text="Baliwag" />
                    <SideControlButton link="/properties/tayabas" text="Tayabas" />

                    <SideControlButton link="/properties/naic" text="Naic" />
                    <SideControlButton link="/properties/magalang" text="Magalang" />

                    <SideControlButton link="/properties/gentri" text="Gen Tri" />
                    <SideControlButton link="/properties/pandi" text="Pandi" />
                </div>
                <div className="finder-content" id="finderContent">
                    <div className="nearby-toggle">
                        <img className="nearby-icon show" id="nearby" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/nearby.png" alt="" onClick={ toggleContent }/>
                        <img className="nearby-icon-alt" id="nearbyAlt" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/nearby-alt.png" alt="" onClick={ toggleContent }/>
                    </div>
                    <Outlet />
                    {/* <div className="other-content">
                        <div className="establishments-cont" id="establishmentsCont">
                            <Establishments id="touristAttraction" class="tourist" title="Tourist Attractions" obj={touristAttraction} url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/tourist-attraction.jpg" />
                            <Establishments id="school" class="school" title="Schools" obj={schools} url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/school.jpg" />
                            <Establishments id="transpo" class="transpo" title="Transportation" obj={transpo} url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/transportation.jpg" />
                            <Establishments id="hospital" class="hospital" title="Hospitals" obj={hospitals} url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/hospital.jpg" />
                            <Establishments id="mall" class="mall" title="Malls" obj={malls} url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/vicinity-map/mall.jpg" />
                        </div>
                        <div className="control-cont">
                            <div className="control">
                                    <a onClick={ () => {scrollLeft()} } id="controlLeft">
                                        <i className="bi-arrow-left-short"></i>
                                    </a>
                                    <a onClick={ () => {scrollRight()} } id="controlRight">
                                        <i className="bi-arrow-right-short"></i>
                                    </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="additional-content" id="additionalContent">
                            <Establishments id="touristAttraction" class="tourist" title="Tourist Attractions" obj={touristAttraction} />
                            <Establishments id="school" class="school" title="Schools" obj={schools} />
                            <Establishments id="transpo" class="transpo" title="Transportation" obj={transpo} />
                            <Establishments id="hospital" class="hospital" title="Hospitals" obj={hospitals} />
                            <Establishments id="mall" class="mall" title="Malls" obj={malls} />
                    </div>
                </div>
            </div>

        </div>
    )
}

const Establishments = (props) => {
    

    useEffect(() => {
        filterItems()
    }, [])

    const filterItems = () => {
        
        var itemsCont = document.getElementById(props.id)
        const names = props.obj.names

        if (itemsCont.innerHTML === "") {
            names.forEach(name => {
                var item = document.createElement("li")

                const items = name.split("|")

                if (items.length === 1) {
                    item.innerHTML = "<p>" + items[0] + "</p>"
                    itemsCont.appendChild(item)
                } else if (items.length === 2) {
                    item.innerHTML = "<p>" + items[0] + "</br><span><i class='bi bi-geo-alt-fill'></i> " + items[1] + "</span></p>"
                    itemsCont.appendChild(item)
                }
            })
        }
    }

    return (
        <div className={ "establishment " + (props.class) }>
            <div className="text-content">
                <h4>{ props.title }</h4>
                <ul id={props.id}>
                </ul>
            </div>
            {/* <div className={"img-cont " +(props.class)}>
                <img src={props.url} alt="" />
            </div> */}
        </div>
    )

}

export { PropertyFinder, VicinityMapAlt }