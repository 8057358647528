import React from "react";

import '../assets/css/blob.css'

// Pulse
const Blob = (props) => {
    return (
        <>
            <div className="blob" style={{ left: (props.x - 12), top: (props.y - 12) }}>
            </div>
        </>
    )
}

export default Blob;