import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

const CorinImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-corin.jpg'
const CartlandImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-cartland.jpg'
const CharlesImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-charles.jpg'
const ChristieImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-christie.jpg'
const PropertiesImg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/PHirst-Editions-Batulao-18.jpg"
const AmenitiesImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/amenities-bg.jpg'
const CharlesImg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/house-charles-remastered.jpg'

const OtherDetails = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/other-details.png'

const CloseButton = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/close.png'



const AdditionalDetails = props => {
    const [ isBalcony, setBalcony] = useState(false)

    return (
        <div className="additional-details">
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-floor-area.png" alt="" loading="lazy"/>
                <h4>{ props.floorArea }</h4>
                <small>Floor area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-lot-area.png" alt="" loading="lazy"/>
                <h4>{ props.lotArea}</h4>
                <small style={{textAlign: "center"}}>Typical<br />Lot area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-shower2.png" alt="" loading="lazy"/>
                <h4>{ props.bathroom }</h4>
                <small>Bathrooms</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bed.png" alt="" loading="lazy"/>
                <h4>{ props.bedroom }</h4>
                <small>Bedrooms</small>
            </div>
            <div className="detail">
                <img src={ isBalcony 
                    ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-balcony.png" 
                    : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-carport.png"} alt="" loading="lazy"/>
                { isBalcony ?
                    <>
                        <h4>{ props.balcony }</h4>
                        <small>Balcony</small>
                    </>
                    :
                    <>
                        <h4>{ props.carport }</h4>
                        <small>Carport</small>
                    </>
                }
            </div>
        </div>
    )
}

const Amenities = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX + 60), top: (props.posY - 120) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + AmenitiesImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                    {/* <h4>Amenities</h4> */}
                </div>
                <h4>Amenities</h4>
                <p className="amenities-desc">Elevate your living experience and find the time to bask in community amenities that you and your family can truly appreciate.</p>
                <div className="modal-btn">
                    <Link to="/amenities" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Properties = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 40), top: (props.posY - 160) }}>
        <div className="modal-body">
            <div className="modal-img" style={{ backgroundImage: "url(" + PropertiesImg + ")"}}>
                <a href="#" onClick={ props.hideModal }>
                    <i className="bi-x"></i>
                </a>
            </div>
            <h4>Sales Pavilion</h4>
            <p>Do you find yourself with a query about your home and community at PHirst Editions? We’d be happy to guide you to make home decisions and address your questions.</p>
            <div className="modal-btn">
                <Link to="/sales-pavilion" ><button>Read More</button></Link>
            </div>
        </div>
    </div>
    )
}

const Cartland = (props) => {
    const details = { id:"cartland", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 420), top: (props.posY - 48) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + CartlandImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4>Cartland 54</h4>
                { <AdditionalDetails {...details} /> }
                <div className="modal-btn">
                    <Link to="/cartland" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Christie = (props) => {
    const details = { id:"christie", lotArea: "110 sqm", floorArea: "90 sqm", bathroom: "3", bedroom: "3", carport: "2" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 400), top: (props.posY - 80) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + ChristieImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                    {/* <h4>Christie 90</h4> */}
                </div>
                <h4>Christie 90</h4>
                {/* <p>Fostering a home where you and your family can enjoy the good things in life is a goal worth pursuing. Growing your family requires a good environment for love and affection.</p> */}
                { <AdditionalDetails {...details} /> }
                <div className="modal-btn">
                    <Link to="/christie" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Corin = (props) => {
    const details = { id:"corin", lotArea: "132 sqm", floorArea: "120 sqm", bathroom: "3", bedroom: "3", balcony: "1", carport: "2" }
    return (

        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 480), top: (props.posY - (props.posY * 0.25)) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + CorinImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4>Corin 120</h4>
                { <AdditionalDetails {...details} /> }
                <div className="modal-btn">
                    <Link to="/corin" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Charles = (props) => {
    const details = { id:"charles", lotArea: "99 sqm", floorArea: "70 sqm", bathroom: "2", bedroom: "3", carport: "2" }
    return (

        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 400), top: props.posY }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + CharlesImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                    {/* <h4>Charles 70</h4> */}
                </div>
                <h4>Charles 70</h4>
                {/* <p>Living that contemporary majesty is no longer just possible; it's an inevitability when you've got the will to make those dreams come true.</p> */}
                { <AdditionalDetails {...details} /> }
                <div className="modal-btn">
                    <Link to="/charles" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const Charles2 = (props) => {
    const details = { id:"charles2", lotArea: "99 sqm", floorArea: "150 sqm", bathroom: "3", bedroom: "3", carport: "2" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 480), top: (props.posY - 48) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + CharlesImg2 + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4>Charles 150<br /><span style={{ fontSize: "16px"}}>Expanded Version</span></h4>
                <br /><small>*Suggested Expansion Plan</small>
                { <AdditionalDetails {...details} /> }
                <div className="modal-btn">
                    <Link to="/charles-expanded" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

// Currently not used
const VideoHotspot = (props) => {

    useEffect(() => {
        var bgMusic = document.getElementById('natureSound')
        bgMusic.pause()
    }, [])
    return (
        <div className="custom-video-modal" onClick={ () => { 
                props.hideModal() 
                var bgMusic = document.getElementById('natureSound')
                bgMusic.play()
            }}>
            <div className="modal-body">
                <div className="modal-close">
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <div className="modal-video">
                    <Vimeo
                    video="https://player.vimeo.com/video/776873698?h=629284cb4a?autoplay=1"
                    autoplay
                    responsive={true}
                    />
                </div>
            </div>
        </div>
    )
}

export { Amenities, Properties, Cartland, Charles, Charles2, Christie, Corin, VideoHotspot }