import react, { useEffect, useState } from "react";

import './assets/css/houses.css'
import ContactTemplate from '../Others/contactTemplate'
import Footer from "../../Partials/components/footer";

import ImageMapper from 'react-img-mapper'

import LoadScreen from "../Others/loadScreen";

const Mapper = props => {
    const url = props.url
    const map = props.map
    
    return <ImageMapper 
                src={url} 
                map={map} 
                parentWidth={400}
                responsive={true}
                stayHighlighted={true}
            />
}

const UnitFloor = (props) => {
    const [ fullView, setFullView ] = useState({ show: false, link: "" })
    const [ links, setLinks ] = useState({ groundFloor: "", secondFloor: "" })

    const floorLinks = {
        cartland: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M54_Cartland_GF_v3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M54_Cartland_2F.jpg"
        ],
        christie: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M90_Christie_GF_v3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M90_Christie_2F.jpg"
        ],
        corin: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M120_Corin_GF_v3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M120_Corin_2F.jpg"
        ],
        charles: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M70_Charles_GF_v3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M70_Charles_2F.jpg"
        ],
        charles2: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/Expanded-Floor-Plan-150-GF.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/Expanded-Floor-Plan-150-2F.jpg"
        ]
    }

    useEffect(() => {
        switch (props.pageName) {
            case "Cartland":
                setLinks((prevState) => {
                    return {
                        ...prevState,
                        groundFloor: floorLinks.cartland[0],
                        secondFloor: floorLinks.cartland[1]
                    }
                })
            break;
            case "Christie":
                setLinks((prevState) => {
                    return {
                        ...prevState,
                        groundFloor: floorLinks.christie[0],
                        secondFloor: floorLinks.christie[1]
                    }
                })
            break;
            case "Corin":
                setLinks((prevState) => {
                    return {
                        ...prevState,
                        groundFloor: floorLinks.corin[0],
                        secondFloor: floorLinks.corin[1]
                    }
                })
            break;
            case "Charles":
                setLinks((prevState) => {
                    return {
                        ...prevState,
                        groundFloor: floorLinks.charles[0],
                        secondFloor: floorLinks.charles[1]
                    }
                })
            break;
            case "Charles2":
                setLinks((prevState) => {
                    return {
                        ...prevState,
                        groundFloor: floorLinks.charles2[0],
                        secondFloor: floorLinks.charles2[1]
                    }
                })
            break;
        }

    }, [])

    const viewImage = (link) => {
        setFullView((prevState) => {
            return {
                show: true,
                link: link
            }
        })
    }
    const closeModal = () => {
        setFullView((prevState) => {
            return {
                show: false
            }
        })
    }

    return (
        <>
            <div className="img-cont floorPlan">    
                <div className="floor-plan">
                    <h6>Ground Floor</h6>
                    <img src={ links.groundFloor } alt="" onClick={ () => {viewImage(links.groundFloor)} }/>
                </div>
                <div className="floor-plan">
                    <h6>2nd Floor</h6>
                    <img src={ links.secondFloor } alt="" onClick={ () => {viewImage(links.secondFloor)} }/>
                </div>
                { fullView.show && <ViewImage link={ fullView.link } closeModal={ closeModal }/> }
            </div>
        </>
    )
}

// const CartlandFloor = () => {
//     return (
//         <>
//             <div className="img-cont" id="cartlandFloorPlan">    
//                 <div className="floor-plan">
//                     <h6>Ground Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plan-v2/M54_Cartland_GF_v3.jpg" alt=""/>
//                 </div>
//                 <div className="floor-plan">
//                     <h6>2nd Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M54_Cartland_2F.jpg" alt=""/>
//                 </div>
//             </div>
//         </>
//     )
// }

// const ChristieFloor = () => {
//     return (
//         <>
//             <div className="img-cont" id="christieFloorPlan">    
//                 <div className="floor-plan">
//                     <h6>Ground Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M90_Christie_GF_v3.jpg" alt=""/>
//                 </div>
//                 <div className="floor-plan">
//                     <h6>2nd Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M90_Christie_2F.jpg" alt=""/>
//                 </div>
//             </div>
//         </>
//     )
// }

// const CorinFloor = () => {
//     return (
//         <>            
//             <div className="img-cont" id="corinFloorPlan">    
//                 <div className="floor-plan">
//                     <h6>Ground Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M105_Corin_GF_v3.jpg" alt=""/>
//                 </div>
//                 <div className="floor-plan">
//                     <h6>2nd Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M105_Corin_2F.jpg" alt=""/>
//                 </div>
//             </div>
//         </>
//     )
// }

// const CharlesFloor = () => {
//     return (
//         <>
//             <div className="img-cont" id="charlesFloorPlan">    
//                 <div className="floor-plan">
//                     <h6>Ground Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M70_Charles_GF_v3.jpg" alt=""/>
//                 </div>
//                 <div className="floor-plan">
//                     <h6>2nd Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M70_Charles_2F.jpg" alt=""/>
//                 </div>
//             </div>
//         </>
//     )
// }

// const Charles2Floor = () => {
//     return (
//         <>
//             <div className="img-cont" id="charlesFloorPlan">    
//                 <div className="floor-plan">
//                     <h6>Ground Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M70_Charles_GF_v3.jpg" alt=""/>
//                 </div>
//                 <div className="floor-plan">
//                     <h6>2nd Floor</h6>
//                     <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M70_Charles_2F.jpg" alt=""/>
//                 </div>
//             </div>
//         </>
//     )
// }

const HouseTemplate = (props) => {
    const [ isClicked, setIsClicked] = useState({ exterior: true, interior: false, floor1: false })
    const [ isBalcony, setBalcony ] = useState(false)

    const [ isCartland, setIsCartland ] = useState()
    const [ isChristie, setIsChristie ] = useState()
    const [ isCorin, setIsCorin ] = useState()
    const [ isCharles, setIsCharles ] = useState()
    const [ isCharles2, setIsCharles2 ] = useState()

    const title = (props.title).split(" ")
    const houseImages = []
    
    const [ isTitleLong, setTitleLong] = useState({ text1: "", text2: ""})

    useEffect(() => {
        if (props.id == "Charles2") {
            setTitleLong((prevState) => ({
                text1: title[2],
                text2: title[3]
            }))
        } else {
            setTitleLong((prevState) => ({
                text1: "",
                text2: ""
            }))
        }

        switch (props.id) {
            case "Cartland":
                return setIsCartland(true)   
            break;
            case "Christie":
                return setIsChristie(true)    
            break;
            case "Corin":
                return setIsCorin(true)    
            break;
            case "Charles":
                return setIsCharles(true)    
            break;
            case "Charles2":
                return setIsCharles2(true) 
            break;
        }

        // if (props.id === "Corin") {
        //     setBalcony(true)
        // } else {
        //     setBalcony(false)
        // }

    }, [])


    // const floorPlan = () => {
    //     switch (props.id) {
    //         case "Cartland":
    //             return <UnitFloor pageName={ props.id } />  
    //         break;
    //         case "Christie":
    //             return <ChristieFloor />    
    //         break;
    //         case "Corin":
    //             return <CorinFloor />    
    //         break;
    //         case "Charles":
    //             return <CharlesFloor />    
    //         break;
    //         case "Charles2":
    //             return <Charles2Floor />    
    //         break;
    //     }
    // }


    const toggleButton = (val) => {
        if (val === "exterior") {
            setIsClicked((prevState) => ({
                ...prevState,
                exterior: true,
                interior: false,
                floor1: false
            }))
            console.log('exterior')
        } else if (val === "interior") {
            setIsClicked((prevState) => ({
                ...prevState,
                exterior: false,
                interior: true,
                floor1: false
            }))
            console.log('interior')
        } else if (val === "floor1") {
            setIsClicked((prevState) => ({
                ...prevState,
                exterior: false,
                interior: false,
                floor1: true
            }))
            console.log('floor1')
        }
    }

    return (
        <>
            <div className="content houses">
                <div className="back-cont">
                    <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
                </div>
            
                <div className="main-img" style={{ backgroundImage: "url(" + props.mainImg + ")"}}>
                    <div>
                        <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/brandlogo-white.png" alt="" loading="eager"/>
                        <h2>{title[0]} {title[1]} <br />{isTitleLong.text1} {isTitleLong.text2}</h2>
                        <a className="redirect-btn" href="#meetCalista">Tap me to scroll down</a>
                    </div>
                    <div className="bg-color"></div>
                </div>
                
                <div className="meet-calista" id="meetCalista">
                    <div className="text-cont">
                        <div className="text">
                            <h4>Meet <i>{title[0]} <span>{title[1]}</span> {isTitleLong.text1} {isTitleLong.text2}</i></h4>
                            <hr />
                            <p>{props.desc}</p>
                            <h5>{ props.quote }</h5>
                        </div>
                        <div className="additional-details">
                            <div className="detail">
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-floor-area.png" alt="" loading="lazy"/>
                                <h4>{ props.additional.floorArea }</h4>
                                <small>Floor area</small>
                            </div>
                            <div className="detail">
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-lot-area.png" alt="" loading="lazy"/>
                                <h4>{ props.additional.lotArea}</h4>
                                <small>Typical Lot area</small>
                            </div>
                            <div className="detail">
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bathroom.png" alt="" loading="lazy"/>
                                <h4>{ props.additional.bathroom }</h4>
                                <small>Bathrooms</small>
                            </div>
                            <div className="detail">
                                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bed.png" alt="" loading="lazy"/>
                                <h4>{ props.additional.bedroom }</h4>
                                <small>Bedrooms</small>
                            </div>
                            <div className="detail">
                                <img src={ isBalcony 
                                    ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-balcony.png" 
                                    : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-carport.png"} alt="" loading="lazy"/>
                                { isBalcony ?
                                    <>
                                        <h4>{ props.additional.balcony }</h4>
                                        <small>Balcony</small>
                                    </>
                                    :
                                    <>
                                        <h4>{ props.additional.carport }</h4>
                                        <small>Carport</small>
                                    </>
                                }
                            </div>
                        </div>
                        <a className="btn-360" href={ props.link }>Check our 360 View</a>
                        {/* <button>Check our 360 View</button> */}
                    </div>
                    <div className="img-cont" style={{ backgroundImage: "url(" + props.mainImg + ")"}}></div>
                </div>

                <div className="banner-cont" style={{ backgroundImage: "url(" + props.banner + ")"}}>
                    <h4>{props.subTitle}</h4>
                    <p>{props.subDesc}</p>
                    <div className="bg-color"></div>
                </div>

                <div className="house-images">
                    <div className="controller">
                        <button id="exterior" className={ isClicked.exterior ? "alt" : ""} onClick={ () => { toggleButton("exterior") } }>
                            <span>
                                Exterior
                            </span>
                        </button>
                        <button id="floor1" className={ isClicked.floor1 ? "alt" : ""} onClick={ () => { toggleButton("floor1") } }>
                            <span>
                                Interior
                            </span>
                        </button>
                        {/* <button id="interior" className={ isClicked.interior ? "alt" : ""} onClick={ () => { toggleButton("interior") } }>
                            <span>
                                <i className="controller-icon interior"></i>
                                2nd Floor
                            </span>
                        </button> */}
                    </div>
                    <div className="img-cont">
                        <div className="exterior-cont" id="exteriorCont" style={ isClicked.exterior ? { display: ""}: { display: "none"}}>
                            <Exterior page={ props.id } />
                        </div>
                        {/* <div className="interior-cont" id="interiorCont" style={ isClicked.interior ? { display: ""}: { display: "none"}}>
                        </div> */}
                        <div className="interior-cont" id="interiorCont" style={ isClicked.floor1 ? { display: ""}: { display: "none"}}>
                            <Interior page={ props.id } />
                        </div>
                    </div>
                </div>

                <div className="floor-plans">
                    <h4>Floor Plan</h4>
                    <hr />
                    <UnitFloor pageName={ props.id } />
                    {/* <Mapper url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/floor-plans/M54_Cartland_GF_v3.jpg" map={props.map} /> */}

                    {
                        props.id === "Corin" ?
                            <ul className="floor-additional-details">
                                <h5>Additional Details</h5>
                                <li>Basic Residential Area: <span>90 sqm</span></li>
                                <li>Balcony: <span>15 sqm</span></li>
                                <li>Covered Parking: <span>15 sqm</span></li>
                            </ul>
                        : <></>
                    }

                    {
                        props.id === "Charles2" ?
                        <ul className="floor-additional-details">
                            <h5>Additional Details</h5>
                            <li>Basic Residential Area: <span>70 sqm</span></li>
                            <li>Additional Residential Area: <span>40 sqm</span></li>
                            <li>Balcony: <span>7 sqm</span></li>
                            <li>Covered Parking: <span>33 sqm</span></li>
                        </ul> : <></>
                    }
                </div>

                { <ContactTemplate /> }
                {/* { <Footer /> } */}
            </div>
        </>
    )
}

const Exterior = (props) => {

    const cartlandImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-exterior-3.jpg"
        ]
    }
    const christieImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-exterior-4.jpg",
        ]
    }
    const corinImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-exterior-8.jpg"
        ]
    }
    const charlesImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-11.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-exterior-4.jpg"
        ]
    }
    const charles2Images = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-exterior-6.jpg"
        ]
    }

    const [ pageState, setPageState ] = useState({ isCartland: false, isChristie: false, isCorin: false, isCharles: false, isCharles2: false})
    const [ fullView, setFullView ] = useState({ show: false, link: "" })

    const [ imgLinks, setImgLinks ] = useState([])

    useEffect(() => {
        switch(props.page) {
            case "Cartland":
                setPageState((prevState) => {
                    return {
                        isCartland: true
                    }
                })
                setImgLinks(cartlandImages.link)
            break;
            case "Charles":
                setPageState((prevState) => {
                    return {
                        isCharles: true
                    }
                })
                setImgLinks(charlesImages.link)
            break;
            case "Charles2":
                setPageState((prevState) => {
                    return {
                        isCharles2: true
                    }
                })
                setImgLinks(charles2Images.link)
            break;
            case "Christie":
                setPageState((prevState) => {
                    return {
                        isChristie: true
                    }
                })
                setImgLinks(christieImages.link)
            break;
            case "Corin":
                setPageState((prevState) => {
                    return {
                        isCorin: true
                    }
                })
                setImgLinks(corinImages.link)
            break;
        }
    }, [])

    const viewImage = (link) => {
        setFullView((prevState) => {
            return {
                show: true,
                link: link
            }
        })
    }
    const closeModal = () => {
        setFullView((prevState) => {
            return {
                show: false
            }
        })
    }

    return (    
        <>
            {
                imgLinks.map((link, key) => {
                    return <img className="img-thumb" src={ link } alt="" onClick={ () => { viewImage(link) } } key={ key }/>
                })
            }
            { fullView.show ? 
                <ViewImage link={ fullView.link } closeModal={ closeModal }/>
                : ""
            }
        </>
    )
}


const Interior = (props) => {
    const cartlandImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/Cartland-interior-1.jpg"
        ]
    }
    const christieImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-interior-10.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/units-interior/christie-90/DSC08413.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/units-interior/christie-90/DSC08347.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/Christie-90-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/units-interior/christie-90/DSC08369.JPG"
        ]
    }
    const corinImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-12.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-15.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-9.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-14.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/Corin-120-interior-1.jpg"
        ]
    }
    const charlesImages = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/Charles-70-interior-5.jpg"
        ]
    }
    const charles2Images = {
        link: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-8.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/Charles-150-interior-3.jpg"
        ]
    }
    
    const [ pageState, setPageState ] = useState({ isCartland: false, isChristie: false, isCorin: false, isCharles: false, isCharles2: false})
    const [ fullView, setFullView ] = useState({ show: false, link: "" })

    const [ imgLinks, setImgLinks ] = useState([])

    useEffect(() => {

        switch(props.page) {
            case "Cartland":
                setPageState((prevState) => {
                    return {
                        isCartland: true
                    }
                })
                setImgLinks(cartlandImages.link)
            break;
            case "Charles":
                setPageState((prevState) => {
                    return {
                        isCharles: true
                    }
                })
                setImgLinks(charlesImages.link)
            break;
            case "Charles2":
                setPageState((prevState) => {
                    return {
                        isCharles2: true
                    }
                })
                setImgLinks(charles2Images.link)
            break;
            case "Christie":
                setPageState((prevState) => {
                    return {
                        isChristie: true
                    }
                })
                setImgLinks(christieImages.link)
            break;
            case "Corin":
                setPageState((prevState) => {
                    return {
                        isCorin: true
                    }
                })
                setImgLinks(corinImages.link)
            break;
        }

    }, [])



    const viewImage = (link) => {
        setFullView((prevState) => {
            return {
                show: true,
                link: link
            }
        })
    }
    const closeModal = () => {
        setFullView((prevState) => {
            return {
                show: false
            }
        })
    }

    return (    
        <>
            {
                imgLinks.map((link, key) => {
                    return <img className="img-thumb" src={ link } alt="" onClick={ () => { viewImage(link) } } key={ key }/>
                })
            }

            { fullView.show ? 
                <ViewImage link={ fullView.link } closeModal={ closeModal }/>
                : ""
            }
        </>
    )
}


const ViewImage = (props) => {
    return (
        <>
            <div className="img-modal">
                <div className="img-cont">
                    <div>
                        <img className="close-btn" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/modal-close-btn.png" alt="" onClick={ props.closeModal }/>
                        <img className="img-content" src={ props.link } alt="" />
                    </div>
                </div>
                <div className="close-div" onClick={ props.closeModal }></div>
            </div>
        </>
    )
}


// Component for Cartland unit page
const UnitCartland = () => {
    const houseDetails = {
        id: "Cartland",
        mainImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/cartland/cartland-main-img.jpg",
        title: "Cartland 54",
        desc: "Live that idyllic life you've always wanted with your family. Start with the charms of a relatively simple but elegantly built home like the Cartland 54. Take the leap and venture to your new adventure. With PHirst Editions’ Cartland 54 unit, you can do just that and more!",
        additional: { lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" },
        detailsImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-details.png",
        descImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-image.png",
        subTitle: "It’s time to elevate life to the next stage, a new edition",
        subDesc: "Expect the exceptional as you truly live the best life that you deserve",
        banner: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/Editions-Family-B5.jpg",
        link: "/cartland-54/360"
    }

    return <HouseTemplate {...houseDetails}/>
}

// Component for Christie unit page
const UnitChristie = () => {

    const houseDetails = {
        id: "Christie",
        mainImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/christie/christie-main-img.jpg",
        title: "Christie 90",
        quote: '"One of the luckiest things that can happen to you in life is to have a happy childhood and a loving home." - Agatha Christie',
        desc: "Fostering a home where you and your family can enjoy the good things in life is a goal worth pursuing. Let your mind wander and explore the possibilities you can do with Christie 90. This unit maximizes your ability to make things happen in your home.",
        additional: { lotArea: "110 sqm", floorArea: "90 sqm", bathroom: "3", bedroom: "3", carport: "2" },
        detailsImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-details.png",
        descImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-image.png",
        subTitle: "It’s time to elevate your life for the next stage – a new Edition",
        subDesc: "Expect the exceptional as you live the best life that you deserve",
        banner: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/Editions-Family-2.jpg",
        link: "/christie-90/360"
    }

    return <HouseTemplate {...houseDetails}/>
}

// Component for Corin unit page
const UnitCorin = () => {

    const houseDetails = {
        id: "Corin",
        mainImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/corin/corin-main-img.jpg",
        title: "Corin 120",
        quote: '“To make a beautiful piece means you have really witnessed it and really made decisions about it”. - Lucy Corin',
        desc: "A home like no other is possible with the modern sensibilities that life can offer. Live that grand life you desire for bigger families! Celebrate life as you like it and go further than you can imagine. The Corin 120 unit lets you live a life that aims to make significant leaps in dreams.",
        additional: { lotArea: "132 sqm", floorArea: "120 sqm", bathroom: "3", bedroom: "3", carport: "2" },
        detailsImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-details.png",
        descImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-image.png",
        subTitle: "It’s time to elevate your life for the next stage – a new Edition",
        subDesc: "Expect the exceptional as you live the best life that you deserve",
        banner: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/Editions-Family-3.jpg",
        link: "/corin-120/360"
    }

    return <HouseTemplate {...houseDetails}/>
}

// Component for Charles 70 unit page
const UnitCharles = () => {

    const houseDetails = {
        id: "Charles",
        mainImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles/charles-main-img.jpg",
        title: "Charles 70",
        quote: '"The most important thing in life is to stop saying ' + "'I Wish'" + ' and start saying ' + "'I Will'" + '." - Charles Dickens',
        desc: "Living that contemporary majesty is no longer just possible. It's an inevitability when you've got the will to make those dreams come true. Live in a home that takes you on a journey to contemporary bliss. The Charles 70 unit brings you closer to a life well lived.",
        additional: { lotArea: "99 sqm", floorArea: "70 sqm", bathroom: "2", bedroom: "3", carport: "2" },
        detailsImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-details.png",
        descImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-image.png",
        subTitle: "It’s time to elevate life to the next stage, a new edition",
        subDesc: "Expect the exceptional as you truly live the best life that you deserve",
        banner: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/Editions-Family-4.jpg",
        link: "/charles-70/360"
    }

    return <HouseTemplate {...houseDetails}/>
}

// Component for Charles 150 unit page
const UnitCharles2 = () => {

    const houseDetails = {
        id: "Charles2",
        mainImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/charles2/charles2-main-img.jpg",
        title: "Charles 150 Expanded Version",
        quote: '"The most important thing in life is to stop saying ' + "'I Wish'" + ' and start saying ' + "'I Will'" + '." - Charles Dickens',
        desc: "Expand your vision! Bring out the potential of your new home and usher in opportunities that make life truly worth living. What makes life interesting is that you can expand everything to fit your dreams. The Charles unit can be expanded to let your visions take hold and start manifesting.",
        additional: { lotArea: "99 sqm", floorArea: "150 sqm", bathroom: "3", bedroom: "3", carport: "2" },
        detailsImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-details.png",
        descImg: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/section1-image.png",
        subTitle: "It’s time to elevate your life for the next stage – a new Edition",
        subDesc: "Expect the exceptional as you live the best life that you deserve",
        banner: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/Editions-Family-5.jpg",
        link: "/charles-150/360"
    }

    return <HouseTemplate {...houseDetails}/>
}


export { UnitCartland, UnitChristie, UnitCorin, UnitCharles, UnitCharles2 }