var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-hallway",
      "name": "Entrance Hallway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.6641070703047465,
        "pitch": 0.14952866966165423,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8990084013821038,
          "pitch": 0.10824193888836042,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": 2.8287119576957807,
          "pitch": 0.19501577884459564,
          "rotation": 0,
          "target": "2-dining-area-and-kitchen"
        },
        {
          "yaw": -2.606991830363828,
          "pitch": 0.03868237881307124,
          "rotation": 1.5707963267948966,
          "target": "3-hallway-to-2-rooms"
        },
        {
          "yaw": -1.4924042635602355,
          "pitch": -0.026501582268391388,
          "rotation": 1.5707963267948966,
          "target": "6-hallway-to-stairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.3360552141567261,
        "pitch": 0.29285024317315766,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.04642781883869063,
          "pitch": 0.027185115465925946,
          "rotation": 7.0685834705770345,
          "target": "6-hallway-to-stairs"
        },
        {
          "yaw": -1.1614644208882101,
          "pitch": -0.05301033538883004,
          "rotation": 6.283185307179586,
          "target": "2-dining-area-and-kitchen"
        },
        {
          "yaw": -0.6907798774124867,
          "pitch": 0.047359600251045464,
          "rotation": 13.351768777756625,
          "target": "3-hallway-to-2-rooms"
        },
        {
          "yaw": 0.4281351952098067,
          "pitch": 0.20488134948022463,
          "rotation": 0,
          "target": "0-entrance-hallway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-dining-area-and-kitchen",
      "name": "Dining Area and Kitchen",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.07179599170603979,
        "pitch": 0.20267890162462798,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.35342652870697044,
          "pitch": 0.10173958288520524,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -0.5456473672822995,
          "pitch": 0.04770377178698304,
          "rotation": 0,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -1.1052029169566637,
          "pitch": 0.039132184044008866,
          "rotation": 7.0685834705770345,
          "target": "6-hallway-to-stairs"
        },
        {
          "yaw": -1.7792043952689074,
          "pitch": 0.031604157334271576,
          "rotation": 0,
          "target": "3-hallway-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-hallway-to-2-rooms",
      "name": "Hallway to 2 Rooms",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.3995855216388513,
        "pitch": 0.0814992156072396,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.4177928458594362,
          "pitch": 0.08580953355860998,
          "rotation": 0,
          "target": "2-dining-area-and-kitchen"
        },
        {
          "yaw": 0.3701658579659721,
          "pitch": 0.05572688543834303,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -0.1059720639919206,
          "pitch": 0.06317925932435031,
          "rotation": 0,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -0.3825859129055651,
          "pitch": 0.17186876338639934,
          "rotation": 4.71238898038469,
          "target": "6-hallway-to-stairs"
        },
        {
          "yaw": -1.7351134719459917,
          "pitch": 0.03892519423884622,
          "rotation": 0,
          "target": "5-storage-area"
        },
        {
          "yaw": -2.0211582891080617,
          "pitch": 0.038077517307248954,
          "rotation": 4.71238898038469,
          "target": "4-bathroom-1st-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.08417476817410119,
        "pitch": 0.13159594576491607,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8453370270662397,
          "pitch": 0.05375753985108034,
          "rotation": 6.283185307179586,
          "target": "3-hallway-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-storage-area",
      "name": "Storage Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.09905646953713187,
        "pitch": 0.17274127562865438,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.7370015699090935,
          "pitch": 0.1090829259057351,
          "rotation": 0,
          "target": "3-hallway-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-hallway-to-stairs",
      "name": "Hallway to Stairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.0031921364202212,
        "pitch": 0.08019462392048382,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.7776144848971782,
          "pitch": 0.061875226323465427,
          "rotation": 0,
          "target": "7-upstairs"
        },
        {
          "yaw": -0.9962830815513275,
          "pitch": 0.050940392375855126,
          "rotation": 0,
          "target": "0-entrance-hallway"
        },
        {
          "yaw": -0.2707995046087994,
          "pitch": 0.07269699103583349,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": 0.2621256400855625,
          "pitch": 0.053295345520773196,
          "rotation": 0,
          "target": "2-dining-area-and-kitchen"
        },
        {
          "yaw": 0.4497301805131997,
          "pitch": 0.1689940036725659,
          "rotation": 1.5707963267948966,
          "target": "3-hallway-to-2-rooms"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.789280944479696,
        "pitch": 0.1668275864035067,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.8830821912543936,
          "pitch": -0.2353783199379631,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        },
        {
          "yaw": -1.4305467791707969,
          "pitch": 0.47313983236535506,
          "rotation": 0,
          "target": "6-hallway-to-stairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.4086006341959916,
        "pitch": 0.08238840861782215,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.5897100113869325,
          "pitch": 0.045400398173296,
          "rotation": 6.283185307179586,
          "target": "9-kids-bedroom-1"
        },
        {
          "yaw": -0.433891990103211,
          "pitch": 0.0595981422198637,
          "rotation": 7.853981633974483,
          "target": "10-bathroom-2nd-floor"
        },
        {
          "yaw": -0.8158297689010148,
          "pitch": 0.06339402173190223,
          "rotation": 0,
          "target": "14-kids-bedroom-2"
        },
        {
          "yaw": -1.1627881063341903,
          "pitch": 0.0527417651248534,
          "rotation": 4.71238898038469,
          "target": "11-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-kids-bedroom-1",
      "name": "Kid's Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.081788324159719,
        "pitch": 0.14707139821409676,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.5877285166800643,
          "pitch": 0.035186057191321396,
          "rotation": 0,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.2997898620701527,
        "pitch": -0.02321793121642557,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.3694767855066896,
          "pitch": 0.013182064963450202,
          "rotation": 0,
          "target": "11-master-bedroom"
        },
        {
          "yaw": 1.585299194817999,
          "pitch": 0.13396436797706102,
          "rotation": 1.5707963267948966,
          "target": "14-kids-bedroom-2"
        },
        {
          "yaw": 1.171999393838652,
          "pitch": 0.14187540921293973,
          "rotation": 4.71238898038469,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.42078301602626667,
        "pitch": 0.10062805782238016,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.3950289884511875,
          "pitch": -0.006270948471495785,
          "rotation": 1.5707963267948966,
          "target": "13-master-bathroom"
        },
        {
          "yaw": -0.055452467861965005,
          "pitch": 0.12053722534001388,
          "rotation": 0,
          "target": "12-master-bedroom-2nd-view"
        },
        {
          "yaw": 2.4454991649065994,
          "pitch": 0.030343773645427063,
          "rotation": 0,
          "target": "9-kids-bedroom-1"
        },
        {
          "yaw": 1.9392067868656229,
          "pitch": 0.04630801015591679,
          "rotation": 0,
          "target": "10-bathroom-2nd-floor"
        },
        {
          "yaw": 2.3068631861414195,
          "pitch": 0.2234034231610309,
          "rotation": 1.5707963267948966,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "12-master-bedroom-2nd-view",
      "name": "Master Bedroom 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.711928667862905,
        "pitch": 0.20274519824845427,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.108134969552623,
          "pitch": -0.018002151890737395,
          "rotation": 1.5707963267948966,
          "target": "13-master-bathroom"
        },
        {
          "yaw": -0.25548601329508536,
          "pitch": 0.08615516270554302,
          "rotation": 6.283185307179586,
          "target": "8-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "13-master-bathroom",
      "name": "Master Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.23816487440367595,
        "pitch": -0.0014562753831306452,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.5848505656874625,
          "pitch": 0.07908527703238022,
          "rotation": 6.283185307179586,
          "target": "11-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "14-kids-bedroom-2",
      "name": "Kid's Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.9607911114936449,
        "pitch": 0.264353501799901,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.3288145987819355,
          "pitch": 0.09180147307062825,
          "rotation": 4.71238898038469,
          "target": "8-hallway-2nd-floor"
        },
        {
          "yaw": -2.0770476591213907,
          "pitch": 0.10311936856744985,
          "rotation": 7.0685834705770345,
          "target": "11-master-bedroom"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-christie-90",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA