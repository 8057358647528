var APP_DATA = {
  "scenes": [
    {
      "id": "0-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.357158856441539,
        "pitch": 0.0976493049367626,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.063759730683179,
          "pitch": 0.03587854002262425,
          "rotation": 7.0685834705770345,
          "target": "1-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.4636088074527862,
        "pitch": 0.10609722307064118,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.215107560367633,
          "pitch": 0.47555399159590905,
          "rotation": 12.566370614359176,
          "target": "0-living-room"
        },
        {
          "yaw": 1.7291705956015377,
          "pitch": -0.3066152990120177,
          "rotation": 0.7853981633974483,
          "target": "2-hallway-second-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-hallway-second-floor",
      "name": "Hallway Second Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.3998390650023289,
        "pitch": 0.06450445884871314,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.276204391166198,
          "pitch": 0.0281920901858701,
          "rotation": 4.71238898038469,
          "target": "3-room-1"
        },
        {
          "yaw": -0.6991412235926298,
          "pitch": 0.02854943961116163,
          "rotation": 1.5707963267948966,
          "target": "4-room-2"
        },
        {
          "yaw": -1.0300994332324862,
          "pitch": 0.03431165720621365,
          "rotation": 0,
          "target": "5-room-3"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-room-1",
      "name": "Room #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0,
        "pitch": 0,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.8087205093210201,
          "pitch": 0.0618400252109943,
          "rotation": 11.780972450961727,
          "target": "2-hallway-second-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-room-2",
      "name": "Room #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -3.1184939218822674,
        "pitch": 0.15337512674913079,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.4541514217983753,
          "pitch": 0.18454749737082032,
          "rotation": 0,
          "target": "2-hallway-second-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-room-3",
      "name": "Room #3",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.4982028984707174,
        "pitch": 0.14720063055846744,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.6607416310242158,
          "pitch": 0.12045714458084689,
          "rotation": 10.995574287564278,
          "target": "2-hallway-second-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-bathroom-second-floor",
      "name": "Bathroom Second Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.3103378918086612,
        "pitch": 0.10960459646645582,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.375366243655849,
          "pitch": -0.01298785947664527,
          "rotation": 1.5707963267948966,
          "target": "2-hallway-second-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "Charles",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": false,
    "viewControlButtons": false
  }
};

export default APP_DATA