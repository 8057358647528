import React from "react"

const News = () => {
    return (
        <div className="content">
            <h2>News</h2>
        </div>
    )
}

export default News