import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Navbar from './Partials/components/navigation';

import Landing from './Pages/LandingPage/components/main'
import About from './Pages/AboutUs/components/about'
import Contact from './Pages/ContactUs/contact'
import News from './Pages/News/news'
import { Amenities } from './Pages/Properties/amenities';
import HouseUnit from './Pages/Properties/houseUnit';

import { UnitCartland, UnitChristie, UnitCorin, UnitCharles, UnitCharles2 } from './Pages/Properties/houses';

import { PropertyFinder, VicinityMapAlt } from './Pages/PropertyFinder/components/finder'


import { Batulao, Tanza, Lipa, Calamba, SanPablo, Baliwag, Tayabas, Naic, Magalang, GenTri, Pandi} from './Pages/Properties/properties';

import VicinityMap from './Pages/VicinityMap/vicinityMap';
import SalesPav from './Pages/SalesPavilion/components/salesPav';
import PrivacyPolicy from './Pages/Others/privacyPolicy';
import ExplorePage from './Pages/ExplorePage/explore';

// 360
import Cartland360 from './Pages/360/cartland-360/component/index'
import Corin360 from './Pages/360/corin-360/component/index-main'
import Chirstie360 from './Pages/360/christie-360/component/index'
import Charles1_360 from './Pages/360/charles1-360/component/index'
import Charles2_360 from './Pages/360/charles2-360/component/index'

import { PageNotFound } from './Partials/components/pageErrorHandler';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


const hideDropdown = () => {
  var mobile = document.getElementById('mobile')
  mobile.classList.remove('show')
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  {/* <React.StrictMode> */}
    <BrowserRouter>
    <Navbar hideDropdown={ hideDropdown } />
      <Routes>
        <Route exact path='/' element={<Landing/>} />
        <Route path='home' element={<Landing/>} />
        <Route path='about' element={<About hideDropdown={ hideDropdown }/>} />
        <Route path='explore' element={ <React.StrictMode><ExplorePage hideDropdown={ hideDropdown }/></React.StrictMode>} />
        <Route path='contact-us' element={<Contact hideDropdown={ hideDropdown }/>}  />
        <Route path='news' element={<News/>} />
        <Route path='amenities' element={<Amenities/>} />
        <Route path='sales-pavilion' element={ <SalesPav/>} />
        <Route path='house-units' element={<HouseUnit/>} />
        <Route path='property-finder' element={<PropertyFinder/>} />
        <Route path='vicinity-map' element={ <VicinityMap/>} />

        {/* <Route path='properties' element={ <VicinityMapAlt />} >
          <Route path='batulao' element={ <Batulao/>} />
          <Route path='tanza' element={ <Tanza/>} />
          <Route path='lipa' element={ <Lipa/>} />
          <Route path='calamba' element={ <Calamba/>} />
          <Route path='sanpablo' element={ <SanPablo/>} />
          <Route path='baliwag' element={ <Baliwag/>} />
          <Route path='tayabas' element={ <Tayabas/>} />
          <Route path='naic' element={ <Naic/>} />
          <Route path='magalang' element={ <Magalang/>} />
          <Route path='gentri' element={ <GenTri/>} />
          <Route path='pandi' element={ <Pandi/>} />
        </Route>  */}

        <Route path='cartland' element={ <UnitCartland />}/>
        <Route path='christie' element={ <UnitChristie />}/>
        <Route path='corin' element={ <UnitCorin />}/>
        <Route path='charles' element={ <UnitCharles />}/>
        <Route path='charles-expanded' element={ <UnitCharles2 />}/>

        <Route path='cartland-54/360' element={ <Cartland360 /> } />
        <Route path='corin-120/360' element={ <Corin360 /> } />
        <Route path='christie-90/360' element={ <Chirstie360 /> } />
        <Route path='charles-70/360' element={ <Charles1_360 /> } />
        <Route path='charles-150/360' element={ <Charles2_360 /> } />

        <Route path='privacy-policy' element={ <PrivacyPolicy hideDropdown={ hideDropdown }/> } />

        <Route path='*' element={ <PageNotFound />} />
      </Routes>
    </BrowserRouter>
  {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
