var APP_DATA = {
  "scenes": [
    {
      "id": "0-cartland-54-1",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.7183999540985848,
        "pitch": 0.2249216876221798,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.5587572153855671,
          "pitch": 0.17712547705746218,
          "rotation": 0.7853981633974483,
          "target": "2-cartland-54-6"
        },
        {
          "yaw": -0.09413830207130403,
          "pitch": 0.1814475419079553,
          "rotation": 11.780972450961727,
          "target": "1-cartland-54-4"
        },
        {
          "yaw": 1.6056635430118273,
          "pitch": -0.027772542684203927,
          "rotation": 5.497787143782138,
          "target": "3-cartland-54-8"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-cartland-54-4",
      "name": "Kitchen and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.2596040353809794,
        "pitch": 0.3357169960429509,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.1350556914614423,
          "pitch": 0.12380447684437001,
          "rotation": 6.283185307179586,
          "target": "2-cartland-54-6"
        },
        {
          "yaw": -1.140592374355684,
          "pitch": -0.01993136568271403,
          "rotation": 5.497787143782138,
          "target": "3-cartland-54-8"
        },
        {
          "yaw": -0.6453087674883786,
          "pitch": 0.17021475981105638,
          "rotation": 0.7853981633974483,
          "target": "0-cartland-54-1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-cartland-54-6",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.2903591978059854,
        "pitch": -0.0025801783539485257,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.082805609316898,
          "pitch": 0.1500636017764112,
          "rotation": 24.347343065320914,
          "target": "0-cartland-54-1"
        },
        {
          "yaw": 2.338857756692314,
          "pitch": 0.33275643369329266,
          "rotation": 2.356194490192345,
          "target": "1-cartland-54-4"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-cartland-54-8",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.91347872605302,
        "pitch": -0.08076555545150832,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.1889595621751923,
          "pitch": 0.3905762249937119,
          "rotation": 14.137166941154074,
          "target": "0-cartland-54-1"
        },
        {
          "yaw": 2.7495389898437272,
          "pitch": -0.39132286674749395,
          "rotation": 10.995574287564278,
          "target": "4-cartland-54-10"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-cartland-54-10",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 1.5360916806767317,
        "pitch": 0.16395860631000225,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.9796034163865812,
          "pitch": 0.03150837380295002,
          "rotation": 5.497787143782138,
          "target": "6-cartland-54-14"
        },
        {
          "yaw": 2.436484523316585,
          "pitch": -0.023583388953849038,
          "rotation": 7.853981633974483,
          "target": "5-cartland-54-12"
        },
        {
          "yaw": 1.9195107645490515,
          "pitch": 0.12442203427483634,
          "rotation": 1.5707963267948966,
          "target": "7-cartland-54-16"
        },
        {
          "yaw": 1.5327796071711246,
          "pitch": 0.37942654010697296,
          "rotation": 0,
          "target": "8-cartland-54-17"
        },
        {
          "yaw": -0.1327796071711246,
          "pitch": 0.57942654010697296,
          "rotation": 3.1,
          "target": "3-cartland-54-8"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-cartland-54-12",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.38251198747047965,
        "pitch": 0.14403166025547165,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.955440606594723,
          "pitch": 0.08806467090057701,
          "rotation": 6.283185307179586,
          "target": "4-cartland-54-10"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-cartland-54-14",
      "name": "Masters Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.4351190220105288,
        "pitch": 0.11067712693006015,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.55440606594723,
          "pitch": 0.08806467090057701,
          "rotation": 10.995574287564278,
          "target": "4-cartland-54-10"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-cartland-54-16",
      "name": "Office Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.06949583748849619,
        "pitch": 0.05934410214081609,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.561365718556763,
          "pitch": 0.0767092127411626,
          "rotation": 0,
          "target": "4-cartland-54-10"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-cartland-54-17",
      "name": "Kids' Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 1.4174353128185437,
        "pitch": 0.22147821507712528,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.4032450673465338,
          "pitch": 0.5183206026240033,
          "rotation": 6.283185307179586,
          "target": "4-cartland-54-10"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "Cartland",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA