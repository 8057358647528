import React, { useEffect, useState } from "react";

import '../assets/css/main.css'
import { coords } from "./coordinates";

import ImageMapper from 'react-img-mapper'

import Controller from "./musicController";
import Modal from "./modal";
import Blob from "./blob";

import LoadScreen from "../../Others/loadScreen";



// Custom Hook to get Browser Window size. Not used.
const Size = () => {

    const [ windowWidth, setWindowWidth ] = useState(0);
    
    const getWidth = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
    
        window.addEventListener('resize', getWidth);
        
        return () => {
            window.removeEventListener('resize', getWidth());
        }

    }, []);
    

    return windowWidth;
}

const windowSize = () => {
    const size = window.innerWidth
    // if ( size <= 1920) {
    //     return window.innerWidth
    // } else {
    //     return 1920
    // }
    return size
    
}

// get center of mapping
const getAreaCenter = (coords) => {
    var coordsArray = coords.split(','), center = []

    var coord, 
        maxX = parseInt(coordsArray[0], 10),
        minX = maxX,
        maxY = parseInt(coordsArray[1], 10),
        minY = maxY;

    for (var i = 0, l = coordsArray.length; i < l; i++) {
        coord = parseInt(coordsArray[i], 10)

        if(i%2 === 0) {
            if (coord < minX) {
                minX = coord
            } else if ( coord > maxX) {
                maxX = coord
            }
        } else {
            if (coord < minY) {
                minY = coord
            } else if (coord > maxY) {
                maxY = coord
            }
        }
    }
    center = [parseInt((minX + maxX) / 2, 10), parseInt((minY + maxY) / 2, 10)];

    return center
}


const Landing = () => {

    const [ isModalVisible, setisModalVisible ] = useState(false)
    const [ pos, setPos] = useState({x: 0,y: 0})

    const [ modalContent, setModalContent] = useState({})

    const [ blobPos1, setBlobPos1 ] = useState({ x: 0, y: 0})
    const [ blobPos2, setBlobPos2 ] = useState({ x: 0, y: 0})
    const [ blobPos3, setBlobPos3 ] = useState( { x: 0, y: 0})
    const [ blobPos4, setBlobPos4 ] = useState( { x: 0, y: 0})
    const [ blobPos5, setBlobPos5 ] = useState( { x: 0, y: 0})
    const [ blobPos6, setBlobPos6 ] = useState( { x: 0, y: 0})
    const [ blobPos7, setBlobPos7 ] = useState( { x: 0, y: 0})
    const [ blobPos8, setBlobPos8 ] = useState( { x: 0, y: 0})
    
    const [ windowWidth, setWindowWidth ] = useState(0)


    const [time, setTime] = useState({ hour: "", minute: "", second: "" })

    const [ isDayOrNight, setDayOrNight] = useState({ day: false, night: false })


    const videoLinks = { 
        day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/editions-landing-page-day-v1.mp4",
        night: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/editions-landing-page-night-v1.mp4" 
    }
    const imgPlaceholderLinks = {
        day: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/landing-page-day-video-placeholder.jpg",
        night: "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/landing-page-night-video-placeholder.jpg"
    }


    const [isLoading, setIsLoading] = useState(true)
    const [ isIntro, setIntro ] = useState()

    // method to get current time
    const getDate = () => {
        var d = new Date();
        setTime((prevTime) => {
            return {
                ...prevTime,
                hour: d.getHours(),
                minute: d.getMinutes(),
                second: d.getSeconds()
            }
        })

        if (d.getHours() >= 6 && d.getHours() < 18) {
            console.log("daytime")
            
            setDayOrNight((prevState) => ({
                ...prevState,
                day: true,
                night: false
            }))

        } else if (d.getHours() >= 18 && d.getHours() < 24) {
            console.log("night")
            setDayOrNight((prevState) => ({
                ...prevState,
                day: false,
                night: true
            }))
        } else if (d.getHours() >= 0 && d.getHours() < 6) {
            console.log("night 2")

            setDayOrNight((prevState) => ({
                ...prevState,
                day: false,
                night: true
            }))
        }
    }

    useEffect(() => {

        if (localStorage.getItem('introCounter') === null){
            localStorage.setItem('introCounter', JSON.stringify({'count': 1}))
        } else {
            const item = localStorage.getItem('introCounter')
            const counter = JSON.parse(item)
            if (counter.count === 1) {
                setIntro(true)
            }
        }

        var bgMusic = document.getElementById('natureSound')
        bgMusic.volume = .12 // Set audio volume

        var navbar = document.getElementById('navBar')
        navbar.classList.add('navigation') // Adds navigation class to navbar

        
        window.addEventListener('resize', areaBlobs) // On window resize, re-calculate blob position
        
        getDate() // Get current time on initial render
        const timer = setInterval(getDate, 1000); // Get current time


        const options = {
            rootMargin: "-150px 0px 0px 120px",
            threshold: .25
        }

        const mobileObserver = new IntersectionObserver(entries => {
            for (let i = 0; i < entries.length; i++) {
                entries[i].target.classList.toggle("show", entries[i].isIntersecting)
            }
        }, options)

        const mobileBtns = document.querySelectorAll(".mobile-btn-v2")

        mobileBtns.forEach(btn => {
            mobileObserver.observe(btn)
        })

        let introHide
        if (!isIntro) {
            var introVideoV2 = document.getElementById("introVideoV2")
            
            introVideoV2.oncanplay = () => {
                setIsLoading(false) 
            }
            introVideoV2.addEventListener('ended', () => {
                console.log("ended")
                introVideoV2.style.opacity = 0
                introHide = setTimeout(() => {
                    introVideoV2.setAttribute('src', "")
                    showBlob()
                    hideIntro()
                }, 400)
            })
        }
        
        
        // Code cleanup
        return () => {
            window.removeEventListener('resize', areaBlobs)
            navbar.classList.remove('navigation')

            clearInterval(timer);
            mobileObserver.disconnect()
            
            clearTimeout(introHide)
        }
    }, [])

    useEffect(() => {
        if (isIntro) {
            var img = document.querySelector(".img-mapper-img")
            img.setAttribute('draggable', false);
    
            var mapper = document.getElementById('img-mapper')
            mapper.style.opacity = 1
            mapper.style.zIndex = "101"

            return () => {
                img.removeAttribute("draggable");
            }
        } else if (!isIntro) {
            var mapper = document.getElementById('img-mapper')
            mapper.style.opacity = 0
            mapper.style.zIndex = "0"

        }

    }, [isIntro])


    const hideIntro = () => {
        // var videoIntro = document.getElementById('videoIntro')
        // videoIntro.style.display = "none"
        setIntro(true)
        // setIntro((prevState) => ({
        //     ...prevState,
        //     isDone: true 
        // }))
    }
    

    const areaBlobs = (val) => {
        modalHide()
        if (val === "initial") {
            // showBlob()
        }

        setWindowWidth(windowSize)

        let areas, areaCoords

        var map = document.querySelectorAll("map.img-mapper-map")
        areas = map.item(0).areas    

        for (let i = 0; i < areas.length; i++) {
            
            areaCoords = areas[i].getAttribute('coords')
        
            let center = getAreaCenter(areaCoords)

            if (i == 0) {
                setBlobPos1((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 1) {
                setBlobPos2((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 2) {
                setBlobPos3((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 3) {
                setBlobPos4((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 4) {
                setBlobPos5((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 5) {
                setBlobPos6((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 6) {
                setBlobPos7((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            } else if (i == 7) {
                setBlobPos8((prevState) => ({
                    ...prevState,
                    x: center[0],
                    y: center[1]        
                }))
            }
        }
    }

    const modalShow = (val) => {

        setisModalVisible(true)
        setModalContent((prevState) => ({
            ...prevState,
            title: val.title
        }))

        setPos((prevState) => {
            return {
                ...prevState,
                x: val.center[0],
                y: val.center[1]
            }
        })

    }

    const modalMobileShow = (val) => {

        setisModalVisible(true)
        setModalContent((prevState) => ({
            ...prevState,
            title: val
        }))

    }

    const modalHide = () => {
        setisModalVisible(false)
        
    }

    const ImageMap = {
        name: 'home-map',
        areas: coords,
    }

    const hideBlob = () => {
        let blobs = document.querySelectorAll('div.blob')

        for (let i = 0 ; i < blobs.length; i++) {
            // if (window.getComputedStyle(blobs.item(i)).animationPlayState !== 'paused') {
            //     blobs.item(i).style.animationPlayState = "paused"
            // }
            // if (window.getComputedStyle(blobs.item(i)).opacity !== '0') {
            //     blobs.item(i).style.opacity = '0'
            //     setTimeout(() => { blobs.item(i).style.display = 'none' }, 400)
            // }
        }
        
    }
    const showBlob = () => {
        let blobs = document.querySelectorAll('div.blob')

        for (let i = 0 ; i < blobs.length; i++) {
            // if (window.getComputedStyle(blobs.item(i)).animationPlayState !== 'running') {
            //     blobs.item(i).style.animationPlayState = "running"
            // }
            if (window.getComputedStyle(blobs.item(i)).opacity != '1') {
                blobs.item(i).style.display = 'block'
                blobs.item(i).style.opacity = '1'
            }
        }
    }

    const closeNav = () => {
        var mobile = document.getElementById('mobile')
        mobile.classList.remove('show')
    }


    return (
        <>
            <LoadScreen isLoading={isLoading}/>
            <div className="content main" id="contentMain" onClick={ closeNav }>

                <iframe src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/silence.mp3" allow="autoplay" id="audio/mp3" style={{ display: "none" }}></iframe>

                <audio id="natureSound" autoPlay loop style={{ display: "none" }}>
                    <source src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/Background.mp3" type="audio/mpeg" />
                    <source src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/audio/landing-page/Background.ogg" type="audio/ogg" />
                </audio>


                <div className="mainContent">
                <Controller />
                    { isModalVisible ? <Modal title={ modalContent.title } posX={ pos.x } posY={ pos.y } hideModal={ modalHide } timestamp={ isDayOrNight.day ? "day" : "night" } />:""}

                    
                    <Blob x={ blobPos1.x - (blobPos1.x * .25) } y={ blobPos1.y - (blobPos1.y * .22)} />
                    <Blob x={ blobPos2.x + (blobPos2.x * .06) } y={ blobPos2.y - (blobPos2.y * .1) } />
                    <Blob x={ blobPos3.x + (blobPos3.x * .005) } y={ blobPos3.y - (blobPos3.y * .45) } />
                    <Blob x={ blobPos4.x + (blobPos4.x * .008) } y={ blobPos4.y - (blobPos4.y * .4) } />
                    <Blob x={ blobPos5.x - (blobPos5.x * .005) } y={ blobPos5.y - (blobPos5.y * .325) } />
                    <Blob x={ blobPos6.x + (blobPos6.x * .005) } y={ blobPos6.y - (blobPos6.y * .245) } />
                    <Blob x={ blobPos7.x + (blobPos7.x * .015) } y={ blobPos7.y - (blobPos7.y * .2) } />
                    {/* <Blob x={ blobPos8.x - (blobPos5.x * .0075) } y={ blobPos8.y - (blobPos5.y * .05) } /> */}

                    <ImageMapper
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/blank.png"
                        map={ ImageMap }
                        responsive={true} 
                        parentWidth={ windowWidth }
                        onImageClick={ modalHide }
                        onClick={ (area) => { modalShow(area) }}
                        onMouseEnter={ hideBlob }
                        onMouseLeave={ showBlob }
                        onLoad={ () => { 
                            areaBlobs("initial") 
                            if (isIntro) {
                                setIsLoading(false) 
                                showBlob()
                            }
                        } }
                    />
                    <div className="video-placeholder">
                        { isDayOrNight.day ? 
                            <img src={ imgPlaceholderLinks.day } alt="" id="daytimeImgPlaceholder" loading="eager"/>
                            : <></>
                        }
                        { isDayOrNight.night ? 
                            <img src={ imgPlaceholderLinks.night } alt="" id="daytimeImgPlaceholder" loading="eager"/>
                            : <></>
                        }
                        {/* <img src={ isDayOrNight.day ? imgPlaceholderLinks.day : imgPlaceholderLinks.night } alt="" id="daytimeImgPlaceholder" loading="eager"/> */}
                        <div className="container" id="">

                            {/* <video className="responsive-iframe" src="https://phirstpark-editions.sgp1.digitaloceanspaces.com/Phirst_video_5_day1.mp4" id="videoRender" width="640" height="360" autoPlay muted loop style={{ display: isDayOrNight.day ? "block" : "none"}}></video>
                            <video className="responsive-iframe" src="https://phirstpark-editions.sgp1.digitaloceanspaces.com/Phirst_video_5_night1.mp4" id="videoRender" width="640" height="360" autoPlay muted loop style={{ display: isDayOrNight.day ? "none" : "block"}}></video> */}
                            {/* <iframe className="responsive-iframe" id="videoRender" src={ isDayOrNight.day ? videoLinks.day : videoLinks.night } width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" muted></iframe> */}

                            {/* {
                                isIntro ? <></>
                                : 
                                <Vimeo
                                    id="videoIntro"
                                    className="responsive-iframe"
                                    video="https://player.vimeo.com/video/779458416"
                                    autoplay
                                    background
                                    muted
                                    responsive
                                    onError={ () => { console.log("error")} }
                                    onPlay={ () => { 
                                        setIsLoading(false)
                                    }}
                                    onEnd={ () => { 
                                        hideIntro()
                                        showBlob()
                                    }}
                                />
                            } */}
                            {
                                !isIntro ?    
                                    <video className="intro-video-v2" src={ isDayOrNight.day ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/editions-intro-day_v1.mp4" : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/video/editions-intro-night_v1.mp4"} id="introVideoV2" autoPlay muted playsInline></video> 
                                : <></>
                            }
                            { isIntro ?
                                <video className="responsive-iframe"src={ isDayOrNight.day ? videoLinks.day : videoLinks.night } id="videoRender" width="640" height="360" autoPlay muted loop></video>
                                : <></>
                            }
                            
                        </div>
                    </div>
                </div>
                
                
                <div className="mobile-control-v2" id="mobileControlV2">
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Sales-Pavilion.jpg"
                        title="Sales Pavilion"
                        link="/sales-pavilion"
                        class="others"
                        showModal={() => { modalMobileShow("Properties") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Cartland-54.jpg"
                        title="Cartland 54"
                        link="/cartland"
                        class="unit"
                        showModal={() => { modalMobileShow("Cartland") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Charles-70.jpg"
                        title="Charles 70"
                        link="/charles"
                        class="unit"
                        showModal={() => { modalMobileShow("Charles") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Charles-150.jpg"
                        title={ "Charles 150 - Expanded Version" }
                        link="/charles-expanded"
                        class="others"
                        showModal={() => { modalMobileShow("Charles2") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Christie-90.jpg"
                        title="Christie 90"
                        link="/christie"
                        class="unit"
                        showModal={() => { modalMobileShow("Christie") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Corin-120.jpg"
                        title="Corin 120"
                        link="/corin"
                        class="unit"
                        showModal={() => { modalMobileShow("Corin") }}
                    />
                    <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Amenities.jpg"
                        title="Amenities"
                        link="/amenities"
                        class="others"
                        showModal={() => { modalMobileShow("Amenities") }}
                    />
                    {/* <MobileButtonV2
                        url="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/landing-page/mobile/Mobile-Vicinity-Map.jpg"
                        title="Vicinity Map"
                        link="/vicinity-map"
                        class="others"
                    /> */}
                </div>
            </div>
        </>
    )
}

// Not used
const MobileButton = (props) => {
    return (
        <a href={ props.link } style={{ textDecoration: "none" }}>
            <div className="mobile-button">
                <div className="img-container" style={{ backgroundImage: "url(" + props.url + ")"}}>
                    {/* <img className="button-image" src={ props.url } alt="" /> */}
                </div>
                
                <h5 className="button-title">{ props.title }</h5>
            </div>
        </a>
    )
}

// Hotspots buttons for mobile view
const MobileButtonV2 = (props) => {
    return (
        <>
            <div className={ "mobile-btn-v2 " +  props.class } style={{ backgroundImage: "url(" + props.url + ")"}} onClick={ props.showModal }>
                {/* <Link to={ props.link }>
                </Link> */}
                <div className="color-overlay"></div>
                    {/* <img className="btn-img" src={ props.url } alt="" /> */}
                <h5 className="btn-title">{ props.title }</h5>
            </div>
        </>
    )
}


export default Landing;