import React, { useEffect, useState } from "react";
import './assets/css/properties.css'
import './assets/css/amenities.css'

import LoadScreen from "../Others/loadScreen";

const GreenBar = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/rectangle-green.png'
const AmenitiesImage1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/amenities-image1.png'
const AmenitiesImage2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/amenities-image2.png'
const AmenitiesImage3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/swimming-pool.jpg'
const AmenitiesImage4 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/amenities-image4.png'

const OutdoorCinema = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Outdoor-Cinema.jpg'

const PetPark = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Pet-Park.jpg'

const TreeHouseBack = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Tree-House-back.jpg'

const TreeHouseFront = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Tree-House-front.jpg'

const WebStation = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Web-Station.jpg'

const WifiNode = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Wifi-Node.jpg'

const HouseDetail1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/images/House-detail.png'

const Amenities = () => {


    const [isLoading, setIsLoading] = useState(true)

    const [ content, setContent] = useState("content1")
    const [ isImageClicked, setImageClicked] = useState({
        image1: true,
        image2: false,
        image3: false,
        image4: false,
        image5: false,
        image6: false,
        image7: false
    })


    useEffect(()=> {
        setIsLoading(false) 
        const setVisibility = setTimeout(()=> {
            // setTextVisible(true)
        }, 1000)

        return () => {
            clearTimeout(setVisibility)
        }
    }, [])
    
    useEffect(() => {
    }, [isImageClicked])

    useEffect(() => {
        SwitchContent()
        focusChecker()       
    }, [content])


    const SwitchContent = (val) => {
        let contentRender
        switch (val) {
            case "content1":
                contentRender = <Content1/>        
                break;
            case "content2": 
                contentRender = <Content2/>
                break;
            case "content3":
                contentRender = <Content3/>
                break;
            case "content4":
                contentRender = <Content4/>
                break;
            case "content5":
                contentRender = <Content5/>
                break;
            case "content6":
                contentRender = <Content6/>
                break;
            case "content7":
                contentRender = <Content7/>
                break;
            default:
                break;
        }
       
        return contentRender
    }

    const focusChecker = () => {
        if (content === "content1") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: true,
                    image2: false,
                    image3: false,
                    image4: false,
                    image5: false,
                    image6: false,
                    image7: false
                }
            })
        } else if (content === "content2") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: true,
                    image3: false,
                    image4: false,
                    image5: false,
                    image6: false,
                    image7: false
                }
            })
        } else if (content === "content3") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: false,
                    image3: true,
                    image4: false,
                    image5: false,
                    image6: false,
                    image7: false
                }
            })
        } else if (content === "content4") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: false,
                    image3: false,
                    image4: true,
                    image5: false,
                    image6: false,
                    image7: false
                }
            })
        } else if (content === "content5") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: false,
                    image3: false,
                    image4: false,
                    image5: true,
                    image6: false,
                    image7: false
                }
            })
        } else if (content === "content6") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: false,
                    image3: false,
                    image4: false,
                    image5: false,
                    image6: true,
                    image7: false
                }
            })
        } else if (content === "content7") {
            setImageClicked((prevState) => {
                return {
                    ...prevState,
                    image1: false,
                    image2: false,
                    image3: false,
                    image4: false,
                    image5: false,
                    image6: false,
                    image7: true
                }
            })
        }
    }

    const clickImage = (val) => {
        // setTextVisible(false)
        setContent(val)
    }

    return (
        <div className="content amenities">
            <div className="back-cont amenities-back">
                <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
            </div>
            <div className="mobile-content">
                <div className="main-section-mobile">
                    <div className="color-overlay"></div>
                    <h2>Amenities</h2>
                    <p>Elevate your living experience and find the time to bask in community amenities that you and your family can truly appreciate.</p>
                    <a href="#panoramaSquare" className="redirect">Read More</a>
                </div>
                <Amenity
                    class="panorama-square"
                    id="panoramaSquare"
                    title="Panorama Square"
                    desc="Some things can get better in an outside environment. Experience the power of cinema together with your community under the magical rays of stars above."
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Outdoor-Cinema.jpg"
                />
                <Amenity
                    class="wifi-nook"
                    id="wifiNook"
                    title="Wifi Nook"
                    desc="Guarantee yourself a life connected to the outside world through the digital space. Work and play will often converge, but you’ve got the tools you need to conveniently get through them in life."
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Wifi-Node.jpg"
                />
                <Amenity
                    class="swimming-pool"
                    id="swimmingPool"
                    title="Swimming Pool"
                    desc="Dip into the water and splash your way to a possible refreshing weekend habit."
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/swimming-pool.jpg"
                />
                <Amenity
                    class="paw-park"
                    id="pawPark"
                    title="Paw Park"
                    desc="Pets need socializing too! Get them to stretch their limbs, leap and hop in the grass, and bask in the sunshine as much as their human parents do. "
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Pet-Park.jpg"
                />
                <Amenity
                    class="play-park"
                    id="playPark"
                    title="Play Park"
                    desc="Life is an exercise in flexibility. Mold your kids to find the balance they need in life and grab hold of dreams that will power them to do their best."
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Web-Station.jpg"
                />
                <Amenity
                    class="tree-house"
                    id="treeHouse"
                    title="Tree House"
                    desc="Let your kids explore the possibilities of life! As they grow older, they’ll have bigger dreams that take root in the adventures they’ve experienced."
                    backgroundImage="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/amenities/Tree-House-front.jpg"
                />
            </div>

            <div>
                <LoadScreen isLoading={isLoading}/>
                <div className="main">
                    <div className="text-container" id="textContent">
                        { SwitchContent(content) }
                    </div>
                    <div className="img-container">
                        <div className="note">
                            <h4>Click an image to see content</h4>
                        </div>
                        <div className="container1">
                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image1 ? "img1 border-highlight" : "img1") } onClick={ () => { clickImage("content1") }}>
                                    <div style={{ backgroundImage: "url(" + TreeHouseFront + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Tree House</h5>
                            </div>

                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image2 ? "img2 border-highlight" : "img2") } onClick={ () => { clickImage("content2") }}>
                                    <div style={{ backgroundImage: "url(" + WebStation + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Play Park</h5>
                            </div>

                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image3 ? "img3 border-highlight" : "img3") } onClick={ () => { clickImage("content3") }}>
                                    <div style={{ backgroundImage: "url(" + WifiNode + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Wifi Nook</h5>
                            </div>

                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image4 ? "img4 border-highlight" : "img4") } onClick={ () => { clickImage("content4") }}>
                                    <div style={{ backgroundImage: "url(" + PetPark + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Paw Park</h5>
                            </div>

                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image5 ? "img5 border-highlight" : "img5") } onClick={ () => { clickImage("content5") }}>
                                    <div style={{ backgroundImage: "url(" + OutdoorCinema + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Panorama Square</h5>
                            </div>

                            {/* <div className={ isImageClicked.image6 ? "img6 border-highlight" : "img6"} onClick={ () => { clickImage("content6") }}>
                                <div style={{ backgroundImage: "url(" + AmenitiesImage4 + ")"}}></div>
                            </div> */}

                            <div className="btn-cont">
                                <div className={ "img-btn " + (isImageClicked.image7 ? "img7 border-highlight" : "img7") } onClick={ () => { clickImage("content7") }}>
                                    <div style={{ backgroundImage: "url(" + AmenitiesImage3 + ")"}}></div>
                                </div>
                                <h5 className="img-btn-text">Swimming Pool</h5>
                            </div>
                        </div>
                        {/* <div className="container1">
                            <div className={ isImageClicked.image1 ? "img1 border-highlight" : "img1"} onClick={ () => { clickImage("content1") }}>
                                <div style={{ backgroundImage: "url(" + AmenitiesImage1 + ")"}}></div>
                            </div>
                            <div className={ isImageClicked.image2 ? "img2 border-highlight" : "img2"} onClick={ () => { clickImage("content2") }}>
                                <div style={{ backgroundImage: "url(" + AmenitiesImage2 + ")"}}></div>
                            </div>                    
                        </div>
                        <div className="container2">
                            <div className={ isImageClicked.image3 ? "img3 border-highlight" : "img3"} onClick={ () => { clickImage("content3") }}>
                                <div style={{ backgroundImage: "url(" + AmenitiesImage3 + ")"}}></div>
                            </div>
                            <div className={ isImageClicked.image4 ? "img4 border-highlight" : "img4"} onClick={ () => { clickImage("content4") }}>
                                <div style={{ backgroundImage: "url(" + AmenitiesImage4 + ")"}}></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
const Amenity = (props) => {

    const [ panoramaShown, setPanoramaShown ] = useState(false)
    const [ wifiShown, setwifiShown ] = useState(false)
    const [ poolShown, setPoolShown ] = useState(false)
    const [ pawParkShown, setPawParkShown ] = useState(false)
    const [ playParkShown, setPlayParkShown ] = useState(false)
    const [ treeHouseShown, setTreeHouseShown ] = useState(false)

    useEffect(() => {
        if (panoramaShown) {
            let amenity = document.getElementById("panoramaSquare")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("panoramaSquare")
            amenity.classList.remove("show")
        }
    }, [panoramaShown])
    
    useEffect(() => {
        if (wifiShown) {
            let amenity = document.getElementById("wifiNook")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("wifiNook")
            amenity.classList.remove("show")
        }
    }, [wifiShown])
    
    useEffect(() => {
        if (poolShown) {
            let amenity = document.getElementById("swimmingPool")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("swimmingPool")
            amenity.classList.remove("show")
        }
    }, [poolShown])
    
    useEffect(() => {
        if (pawParkShown) {
            let amenity = document.getElementById("pawPark")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("pawPark")
            amenity.classList.remove("show")
        }
    }, [pawParkShown])
    
    useEffect(() => {
        if (playParkShown) {
            let amenity = document.getElementById("playPark")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("playPark")
            amenity.classList.remove("show")
        }
    }, [playParkShown])
    
    useEffect(() => {
        if (treeHouseShown) {
            let amenity = document.getElementById("treeHouse")
            amenity.classList.add("show")
        } else {
            let amenity = document.getElementById("treeHouse")
            amenity.classList.remove("show")
        }
    }, [treeHouseShown])

    
    
    const toggleContent = (title) => {
        switch (title) {
            case "Panorama Square":
                setPanoramaShown((prevState) => !prevState)
                break;
            case "Wifi Nook":
                setwifiShown((prevState) => !prevState)
                break;
            case "Swimming Pool":
                setPoolShown((prevState) => !prevState)
                break;
            case "Paw Park":
                setPawParkShown((prevState) => !prevState)
                break;
            case "Play Park":
                setPlayParkShown((prevState) => !prevState)
                break;
            case "Tree House":
                setTreeHouseShown((prevState) => !prevState)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className={"amenity-mobile " + props.class } id={ props.id } style={{ backgroundImage: "url(" + props.backgroundImage + ")"}}>
                <div className="color-overlay"></div>
                <h4 className="amenity-title">{ props.title }</h4>
                <p className="amenity-desc">{ props.desc }</p>
                <img className="info-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/properties/info-icon.png" alt="" onClick={ () => { toggleContent(props.title) } } />
            </div>
        </>
    )
}

const Content1 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Tree House</h2>
            </div>
            <p>Let your kids explore the possibilities of life! As they grow older, they’ll have bigger dreams that take root in the adventures they’ve experienced.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" /> */}
            {/* <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}

const Content2 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Play Park</h2>
            </div>
            <p>Life is an exercise in flexibility. Mold your kids to find the balance they need in life and grab hold of dreams that will power them to do their best.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}


const Content3 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Wifi Nook</h2>
            </div>
            <p>Guarantee yourself a life connected to the outside world through the digital space. Work and play will often converge, but you’ve got the tools you need to conveniently get through them in life.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}

const Content4 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Paw Park</h2>
            </div>
            <p>Pets need socializing too! Get them to stretch their limbs, leap and hop in the grass, and bask in the sunshine as much as their human parents do. </p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}


const Content5 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Panorama Square</h2>
            </div>
            <p>Some things can get better in an outside environment. Experience the power of cinema together with your community under the magical rays of stars above.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}


const Content6 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Basketball Court</h2>
            </div>
            <p>Hone the spirit of competition and practice sportsmanship. Sports bring out the potential of an active community.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}


const Content7 = () => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="bar"></div>
                <h2>Swimming Pool</h2>
            </div>
            <p>Dip into the water and splash your way to a possible refreshing weekend habit.</p>
            {/* <img className="house-detail" src={ HouseDetail1 } alt="" />
            <button className="more-info">More Info</button> */}
        </React.Fragment>
    )
}


const Amenitiesv2 = () => {
    return (
        <>
            <div className="content amenities-v2">
                <div className="back-cont amenities-back">
                    <a href="/" className="back-btn"><i className="bi-house-fill"></i> Go back</a>
                </div>
            </div>
        </>
    )
}

export { Amenities }